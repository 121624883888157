import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface ButtonSpec {
  name: string;
  icon: string;
  tip: string;
}

@Component({
  selector: 'app-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss'],
})
export class ContentCardComponent implements OnInit {
  constructor() {}

  @Input() title: string;
  @Input() subTitle: string;
  @Input() image: string;
  @Input()
  set buttons(buttons: ButtonSpec[]) {
    // Maximum of 4 buttons
    this._buttons = buttons.slice(0, 4);
  }

  @Output() action = new EventEmitter<string>(true);

  get buttons() {
    return this._buttons;
  }

  private _buttons: ButtonSpec[];

  ngOnInit() {}

  buttonAction(buttonName) {
    this.action.emit(buttonName);
  }
}
