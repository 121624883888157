import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../../core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { QrDialogComponent } from './qr-dialog.component';

const LINK_URL = 'https://qr.btl.vin/';

@Component({
  selector: 'app-qr-tag',
  templateUrl: './qr-tag.component.html',
  styleUrls: ['./qr-tag.component.scss'],
})
export class QrTagComponent implements OnInit {
  constructor(private api: ApiService, private snackBar: MatSnackBar, private dialog: MatDialog) {}

  @Input() itemType: string;
  @Input() itemName = '';
  @Input() context: string;
  @Input() contextId: string;
  @Input() domain: string;

  ngOnInit() {}

  createQR() {
    this.api.post(`/tags/type/QR/context/${this.context}/id/${this.contextId}`).subscribe(
      tag => {
        this.displayQRImage(tag._id, tag.context, tag.contextId);
      },
      error => {
        this.snackBar.open(`QR creation failed: ${error.message}`, 'dismiss', {
          duration: 8000,
          verticalPosition: 'top',
        });
      }
    );
  }

  displayQRImage(tagId: String, context: String, contextId: String) {
    let linkUrl;
    if (this.domain) {
      linkUrl = `https://${this.domain}/`;
    } else {
      linkUrl = LINK_URL;
    }
    const qrLink = `${linkUrl}?id=${tagId}&ctx=${context}&cid=${contextId}`;
    this.dialog.open(QrDialogComponent, {
      data: {
        name: this.itemName || this.itemType,
        link: qrLink,
      },
    });
  }
}
