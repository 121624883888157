// src/app/auth/auth.service.ts
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as auth0 from 'auth0-js';
// noinspection TypeScriptPreferShortImport
import { Constants } from '../../scripts';

const APP_NS = 'http://bottlevin/';

@Injectable()
export class AuthService {
  constructor(private router: Router) {
    // If app auth token is not expired, request new token
    if (JSON.parse(localStorage.getItem('expires_at')) > Date.now()) {
      this.renewToken();
    }
  }

  static get tokenValid(): boolean {
    // Check if current time is past access token's expiration
    return Date.now() < JSON.parse(localStorage.getItem('expires_at'));
  }

  // Create Auth0 web auth instance
  private _auth0 = new auth0.WebAuth({
    clientID: environment.auth.CLIENT_ID,
    domain: environment.auth.CLIENT_DOMAIN,
    responseType: 'token',
    redirectUri: environment.auth.REDIRECT,
    audience: environment.auth.AUDIENCE,
    scope: environment.auth.SCOPE,
  });
  accessToken: string;
  appKey: string;
  userProfile: any;
  expiresAt: number;
  // Create a stream of logged in status to communicate throughout app
  loggedIn: boolean = false;
  loggedIn$ = new BehaviorSubject<boolean>(this.loggedIn);
  loggingIn: boolean;

  private static _clearExpiration() {
    // Remove token expiration from localStorage
    localStorage.removeItem('expires_at');
  }

  private static _clearRedirect() {
    // Remove redirect from localStorage
    localStorage.removeItem('authRedirect');
  }

  private static serverPerms(appData) {
    if (appData.servers) {
      const server = window.location.hostname.split('.')[0];
      // If the account has server-specific restrictions then return them
      const perms = appData.servers[server];
      if (perms === true) {
        // Boolean value true is shortcut for all permissions
        return Constants.ALL_PERMS;
      }
      if (typeof perms === 'object') {
        return perms;
      }
      return null;
    }
    // If there are no server-based permissions then they get all permissions (legacy)
    return Constants.ALL_PERMS;
  }

  setLoggedIn(value: boolean) {
    // Update login status subject
    this.loggedIn$.next(value);
    this.loggedIn = value;
  }

  login() {
    // Auth0 authorize request
    this._auth0.authorize();
  }

  handleAuth() {
    // When Auth0 hash parsed, get profile
    this._auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken) {
        window.location.hash = '';
        this._getProfile(authResult);
      } else if (err) {
        AuthService._clearRedirect();
        this.router.navigate(['/']);
        console.error(`Error authenticating: ${err.error}`);
      }
    });
  }

  appAdmin(): boolean {
    return this.appKey != null;
  }

  isAdmin(): boolean {
    return this.userProfile && this.userProfile.type === 'admin';
  }

  hasPerm(perm): boolean {
    return this.userProfile && this.userProfile.perms && this.userProfile.perms[perm];
  }

  private _getProfile(authResult) {
    this.loggingIn = true;
    // Use access token to retrieve user's profile and set session
    this._auth0.client.userInfo(authResult.accessToken, (err, profile) => {
      if (profile) {
        this._setSession(authResult, profile);
        this._redirect();
      } else if (err) {
        console.warn(`Error retrieving profile: ${err.error}`);
      }
    });
  }

  private _setSession(authResult, profile?) {
    this.expiresAt = authResult.expiresIn * 1000 + Date.now();
    // Store expiration in local storage to access in constructor
    localStorage.setItem('expires_at', JSON.stringify(this.expiresAt));

    this.accessToken = authResult.accessToken;
    this.userProfile = profile;
    const appData = profile[`${APP_NS}app_metadata`];
    if (appData) {
      this.userProfile.type = appData.type;
      this.appKey = appData.appId;
    }
    this.userProfile.perms = AuthService.serverPerms(appData);
    const userData = profile[`${APP_NS}user_metadata`];
    if (userData) {
      this.userProfile.name = {
        given: userData.given_name,
        family: userData.family_name,
      };
    }
    // Update login status in loggedIn$ stream
    this.setLoggedIn(true);
    this.loggingIn = false;
  }

  private _redirect() {
    const redirect = localStorage.getItem('authRedirect');
    const navArr = [redirect ? decodeURI(redirect) : '/'];

    this.router.navigate(navArr);
    // Redirection completed; clear redirect from storage
    AuthService._clearRedirect();
  }

  logout() {
    // Remove data from localStorage
    AuthService._clearExpiration();
    // End Auth0 authentication session
    this._auth0.logout({
      clientId: environment.auth.CLIENT_ID,
      returnTo: environment.auth.LOGOUT_URL,
    });
    AuthService._clearRedirect();
  }

  renewToken() {
    // Check for valid Auth0 session
    this._auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken) {
        this._getProfile(authResult);
      } else {
        AuthService._clearExpiration();
      }
    });
  }
}
