import { HttpParams } from '@angular/common/http';
import { List } from 'immutable';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./auth.service";
export class MerchantStoreService {
    constructor(apiService, auth) {
        this.apiService = apiService;
        this.auth = auth;
        this._merchants$ = new BehaviorSubject(List([]));
        this._fetchingMerchants = false;
        this._fetched = false;
        this._fetchingAll = false;
        this._allFetched = false;
        this._fetchingDocs = false;
        this._docs$ = new BehaviorSubject(List([]));
        this._lastError = new Subject();
        this.lastError$ = this._lastError.asObservable();
        this._merchantFilter = JSON.parse(localStorage.getItem('wineryFilter')) || 'all';
        // This list may be filtered
        this.merchants$ = this.merchantsRaw$.pipe(map(merchants => merchants.filter(m => {
            switch (this._merchantFilter) {
                case 'active':
                    return !m.inactive;
                case 'inactive':
                    return m.inactive;
                default:
                    return true;
            }
        })));
        this._AllMerchantID = '__ALL__';
        // This is placeholder entry for selected all wineries within the list (only available to Admin)
        this._allMerchant = {
            _id: this._AllMerchantID,
            name: 'All Merchants',
            contact: {
                address: {
                    street_address: '1171 Homestead Rd',
                    locality: 'Santa Clara',
                    region: 'CA',
                    postal_code: '95050',
                    country: 'USA',
                },
                email: 'admin@bottlevin.com',
                phone: '',
                website: 'https://www.bottlevin.com',
            },
        };
    }
    get merchantFilter() {
        return this._merchantFilter;
    }
    set merchantFilter(filterValue) {
        this._merchantFilter = filterValue;
        this._merchants$.next(this._merchants$.value);
        localStorage.setItem('wineryFilter', JSON.stringify(filterValue));
    }
    get merchantsRaw$() {
        if (!this._fetchingMerchants && !this._fetched) {
            this._fetchingMerchants = true;
            this.apiService
                .get('/merchants')
                .pipe(finalize(() => {
                this._fetchingMerchants = false;
                this._fetched = true;
            }))
                .subscribe(merchants => {
                this._merchants$.next(List(merchants));
            }, error => {
                this.setLastError('Retrieving merchants', error.message);
            });
        }
        return this._merchants$.asObservable();
    }
    get merchantsAll$() {
        if (!this._fetchingAll && !this._allFetched) {
            let params;
            if (this.auth.isAdmin()) {
                params = new HttpParams().set('details', 'true');
            }
            this._fetchingAll = true;
            this.apiService
                .get('/merchants', params)
                .pipe(finalize(() => {
                this._fetchingAll = false;
                this._allFetched = true;
            }))
                .subscribe(merchants => {
                if (this.auth.isAdmin()) {
                    merchants = [this._allMerchant, ...merchants];
                }
                this._merchants$.next(List(merchants));
            }, error => {
                this.setLastError('Retrieving merchants', error.message);
            });
        }
        return this._merchants$.asObservable();
    }
    isAll(merchant) {
        return merchant._id === this._AllMerchantID;
    }
    refetchMerchants() {
        this._merchants$.next(List([]));
        if (!this._fetchingMerchants) {
            this._fetchingMerchants = true;
            this.apiService
                .get('/merchants')
                .pipe(finalize(() => {
                this._fetchingMerchants = false;
                this._fetched = true;
            }))
                .subscribe(merchants => {
                this._merchants$.next(List(merchants));
            }, error => {
                this.setLastError('Retrieving merchants', error.message);
            });
        }
        return this._merchants$.asObservable();
    }
    fetchMerchant(merchantId, force = false) {
        const merchant$ = new BehaviorSubject({});
        const _merchants = this._merchants$.getValue();
        const _merchant = _merchants.find(m => m._id === merchantId);
        if (_merchant) {
            const merchantDetail = _merchant;
            if (!force && Array.isArray(merchantDetail.admins)) {
                merchant$.next(merchantDetail);
            }
            else {
                this.apiService.get(`/merchants/${merchantId}`).subscribe(fetchedMerchant => {
                    this.updateMerchantInList(fetchedMerchant);
                    merchant$.next(fetchedMerchant);
                }, error => {
                    this.setLastError(`Retreiving merchant ${merchantId}`, error.message);
                });
            }
        }
        else {
            this.setLastError(`Retreiving merchant`, `merchant with id ${merchantId} not in list`);
        }
        return merchant$.asObservable();
    }
    addMerchant(merchant) {
        const merchant$ = new BehaviorSubject({});
        this.apiService.post('/merchants', merchant).subscribe(newMerchant => {
            this._merchants$.next(this._merchants$.getValue().push(newMerchant));
            merchant$.next(newMerchant);
        });
        return merchant$.asObservable();
    }
    saveMerchant(merchant) {
        const merchant$ = new BehaviorSubject(merchant);
        this.apiService.put(`/merchants/${merchant._id}`, merchant).subscribe(savedMerchant => {
            this.updateMerchantInList(savedMerchant);
            merchant$.next(savedMerchant);
        });
        return merchant$.asObservable();
    }
    addProduct(merchant, product) {
        const merchant$ = new BehaviorSubject(merchant);
        this.apiService.post(`/merchants/${merchant._id}/products`, product).subscribe(savedMerchant => {
            this.updateMerchantInList(savedMerchant);
            merchant$.next(savedMerchant);
        });
        return merchant$.asObservable();
    }
    updateProduct(merchant, product) {
        const merchant$ = new BehaviorSubject(merchant);
        this.apiService.put(`/merchants/${merchant._id}/products/${product._id}`, product).subscribe(savedMerchant => {
            this.updateMerchantInList(savedMerchant);
            merchant$.next(savedMerchant);
        });
        return merchant$.asObservable();
    }
    removeProduct(merchant, productId) {
        const merchant$ = new BehaviorSubject(merchant);
        this.apiService.delete(`/merchants/${merchant._id}/products/${productId}`).subscribe(savedMerchant => {
            this.updateMerchantInList(savedMerchant);
            merchant$.next(savedMerchant);
        });
        return merchant$.asObservable();
    }
    setLastError(context, message, merchant, detail) {
        this._lastError.next({
            context,
            message,
            merchant,
            detail,
            formatted: `${context} failed because ${message}`,
        });
    }
    updateMerchantInList(merchant) {
        const _merchants = this._merchants$.getValue();
        const index = _merchants.findIndex(w => w._id === merchant._id);
        this._merchants$.next(_merchants.set(index, merchant));
    }
    newEvent(merchant, event) {
        const merchant$ = new BehaviorSubject(merchant);
        this.apiService.post(`/merchants/${merchant._id}/events`, event).subscribe(updatedMerchant => {
            this.updateMerchantInList(updatedMerchant);
            merchant$.next(updatedMerchant);
        }, error => {
            this.setLastError(`Adding new event to ${merchant.category}`, error.message, merchant);
        });
        return merchant$.asObservable();
    }
    addEvent(merchant, eventId) {
        const merchant$ = new BehaviorSubject(merchant);
        this.apiService.put(`/merchants/${merchant._id}/events/${eventId}`).subscribe(updatedMerchant => {
            this.updateMerchantInList(updatedMerchant);
            merchant$.next(updatedMerchant);
        }, error => {
            this.setLastError(`Adding event to ${merchant.category}`, error.message, merchant);
        });
        return merchant$.asObservable();
    }
    removeEvent(merchant, event) {
        const merchant$ = new BehaviorSubject(merchant);
        this.apiService.delete(`/merchants/${merchant._id}/events/${event._id}`).subscribe(updatedMerchant => {
            this.updateMerchantInList(updatedMerchant);
            merchant$.next(updatedMerchant);
        }, error => {
            this.setLastError(`Removing event from ${merchant.category}`, error.message, merchant);
        });
        return merchant$.asObservable();
    }
    addPromo(merchant, promo) {
        const merchant$ = new BehaviorSubject(merchant);
        this.apiService.post(`/merchants/${merchant._id}/promos`, promo).subscribe(savedMerchant => {
            this.updateMerchantInList(savedMerchant);
            merchant$.next(savedMerchant);
        });
        return merchant$.asObservable();
    }
    removePromo(merchant, promo) {
        const merchant$ = new BehaviorSubject(merchant);
        this.apiService.delete(`/merchants/${merchant._id}/promos/${promo._id}`).subscribe(savedMerchant => {
            this.updateMerchantInList(savedMerchant);
            merchant$.next(savedMerchant);
        });
        return merchant$.asObservable();
    }
    updatePromo(merchant, promo) {
        const merchant$ = new BehaviorSubject(merchant);
        this.apiService.put(`/merchants/${merchant._id}/promos/${promo._id}`, promo).subscribe(savedMerchant => {
            this.updateMerchantInList(savedMerchant);
            merchant$.next(savedMerchant);
        });
        return merchant$.asObservable();
    }
    addTasting(merchant, tasting) {
        const merchant$ = new BehaviorSubject(merchant);
        this.apiService.post(`/merchants/${merchant._id}/tastings`, tasting).subscribe(savedMerchant => {
            this.updateMerchantInList(savedMerchant);
            merchant$.next(savedMerchant);
        }, error => {
            this.setLastError('Adding tasting', error.message, merchant, tasting);
        });
        return merchant$.asObservable();
    }
    removeTastingByIndex(merchant, index) {
        const tasting = merchant.menu[index];
        const merchant$ = new BehaviorSubject(merchant);
        this.apiService.delete(`/merchants/${merchant._id}/tastings/${tasting._id}`).subscribe(updatedWinery => {
            this.updateMerchantInList(updatedWinery);
            merchant$.next(updatedWinery);
        }, error => {
            this.setLastError('Removing tasting', error.message, merchant, tasting);
        });
        return merchant$.asObservable();
    }
    fetchTastingByIndex(merchant, index) {
        const fetchedTasting = new BehaviorSubject({});
        if (merchant.menu.length > index) {
            const tasting = merchant.menu[index];
            this.apiService.get(`/merchants/${merchant._id}/tastings/${tasting._id}`).subscribe(wineTasting => {
                fetchedTasting.next(wineTasting);
            }, error => {
                this.setLastError('Fetching tasting', error.message, merchant, tasting);
            });
        }
        else {
            this.setLastError('Fetching tasting', `invalid index ${index} supplied`, merchant);
        }
        return fetchedTasting.asObservable();
    }
    saveTasting(merchant, tasting) {
        const savedTasting = new BehaviorSubject({});
        this.apiService.put(`/merchants/${merchant._id}/tastings/${tasting._id}`, tasting).subscribe(tastingDetail => {
            const tindex = merchant.menu.findIndex(t => t._id === tasting._id);
            merchant.menu[tindex] = tastingDetail;
            this.updateMerchantInList(merchant);
            savedTasting.next(tastingDetail);
        }, error => {
            this.setLastError('Saving Tasting', error.message, merchant, tasting);
        });
        return savedTasting.asObservable();
    }
    getDocs(merchant) {
        if (merchant.docs.length === 0 || typeof merchant.docs[0] === 'object') {
            console.log(`Returning cached docs for ${merchant._id}`);
            const docs = merchant.docs;
            this._docs$.next(List(docs));
        }
        else if (!this._fetchingDocs) {
            console.log(`Fetching docs for ${merchant._id}`);
            this.apiService.get(`/merchants/${merchant._id}/docs`).subscribe(docs => {
                merchant.docs = docs;
                this.updateMerchantInList(merchant);
                this._docs$.next(List(docs));
            }, error => {
                this.setLastError('Fetching Docs', error.message, merchant);
            }, () => {
                this._fetchingDocs = true;
            });
        }
        return this._docs$.asObservable();
    }
    addDocList(merchant, data) {
        this.apiService.post(`/merchants/${merchant._id}/docs`, data).subscribe(updatedMerchant => {
            this.updateMerchantInList(updatedMerchant);
            this._docs$.next(List(updatedMerchant.docs));
        }, error => {
            this.setLastError('Adding Doc List', error.message, merchant);
        });
        return this._docs$.asObservable();
    }
    updateDocList(merchant, data) {
        this.apiService.put(`/merchants/${merchant._id}/docs/${data._id}`, data).subscribe(updatedMerchant => {
            this.updateMerchantInList(updatedMerchant);
            this._docs$.next(List(updatedMerchant.docs));
        }, error => {
            this.setLastError('Editing Doc List', error.message, merchant);
        });
        return this._docs$.asObservable();
    }
    deleteDocList(merchant, data) {
        this.apiService.delete(`/wineries/${merchant._id}/docs/${data._id}`).subscribe(updatedMerchant => {
            this.updateMerchantInList(updatedMerchant);
            this._docs$.next(List(updatedMerchant.docs));
        }, error => {
            this.setLastError('Removing Doc List', error.message, merchant);
        });
        return this._docs$.asObservable();
    }
    updateMerchantDocs(merchant, data) {
        const docIds = data.map(d => d._id);
        this.apiService.put(`/wineries/${merchant._id}`, Object.assign({}, merchant, { docs: docIds })).subscribe(updatedMerchant => {
            this.updateMerchantInList(updatedMerchant);
            this._docs$.next(List(updatedMerchant.docs));
        }, error => {
            this.setLastError(`Saving ${merchant.category}`, error.message, merchant);
        });
        return this._docs$.asObservable();
    }
}
MerchantStoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MerchantStoreService_Factory() { return new MerchantStoreService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.AuthService)); }, token: MerchantStoreService, providedIn: "root" });
