import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, mergeMap, map, switchMap } from 'rxjs/operators';
import { AuthService, Merchant, Winery, MerchantStoreService, WineryStoreService, EventsService, EventItem, App } from '../../core';
import { NavigationEnd, RouteConfigLoadEnd, Router } from '@angular/router';
import { AppsService } from '../../core/services/apps.service';
import { Constants } from '../../scripts';

@Component({
  selector: 'app-layout-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  constructor(
    public wineryStore: WineryStoreService,
    public merchantStore: MerchantStoreService,
    public eventsService: EventsService,
    public appsService: AppsService,
    public router: Router,
    public auth: AuthService
  ) {}

  Perms = Constants.Perms;
  ReportTypes = Constants.ReportTypes;
  selectedWinery$: Observable<Winery>;
  selectedMerchant$: Observable<Merchant>;
  selectedEvent$: Observable<EventItem>;
  selectedApp$: Observable<App>;

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd || event instanceof RouteConfigLoadEnd) {
        this.selectedWinery$ = undefined;
        this.selectedMerchant$ = undefined;
        this.selectedEvent$ = undefined;
        this.selectedApp$ = undefined;
        if (this.router.url.startsWith('/cms/wineries')) {
          const components = this.router.url.split('/');
          const wineryId = components[3];
          this.selectedWinery$ = this.wineryStore.wineFilter$.pipe(
            switchMap(f =>
              this.wineryStore.wineries$.pipe(
                filter(w => w.size > 0),
                mergeMap(() => this.wineryStore.fetchWinery(wineryId).pipe(map(winery => this.wineryStore.filteredWinery(winery, f))))
              )
            )
          );
        } else if (this.router.url.startsWith('/cms/merchants')) {
          const components = this.router.url.split('/');
          const merchantId = components[3];
          this.selectedMerchant$ = this.merchantStore.merchants$.pipe(
            filter(m => m.size > 0),
            map(merchants => merchants.find(w => w._id === merchantId))
          );
        } else if (this.router.url.startsWith('/cms/events')) {
          const components = this.router.url.split('/');
          const eventId = components[3];
          this.selectedEvent$ = this.eventsService.events$.pipe(
            filter(e => e.size > 0),
            map(events => events.find(e => e._id === eventId))
          );
        } else if (this.router.url.startsWith('/cms/apps')) {
          const components = this.router.url.split('/');
          const appId = components[3];
          this.selectedApp$ = this.appsService.apps$.pipe(
            filter(a => a.size > 0),
            map(apps => apps.find(a => a._id === appId))
          );
        }
      }
    });
  }
}
