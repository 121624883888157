import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { List } from 'immutable';
import { finalize } from 'rxjs/operators';
import { ApiService } from './api.service';
import { NotificationCounts, NotificationMessage, Story } from '../models';
import { StoryError } from './features-store.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationStoreService {
  private _notifications: BehaviorSubject<List<NotificationMessage>> = new BehaviorSubject(List([]));
  private _fetchingNotifications = false;
  private _fetched = false;

  private _lastError: Subject<StoryError> = new Subject<StoryError>();
  public lastError$: Observable<StoryError> = this._lastError.asObservable();

  constructor(private apiService: ApiService) {}

  get notifications$() {
    if (!this._fetchingNotifications && !this._fetched) {
      this._fetchingNotifications = true;
      this.apiService
        .get('/notifications')
        .pipe(
          finalize(() => {
            this._fetchingNotifications = false;
            this._fetched = true;
          })
        )
        .subscribe(
          notifications => {
            this._notifications.next(List(notifications));
          },
          error => {
            this.setLastError('Retreiving Notifications', error.message);
          }
        );
    }
    return this._notifications.asObservable();
  }

  private setLastError(context: string, message: string, story?: Story) {
    this._lastError.next({
      context: context,
      message: message,
      story: story,
      formatted: `${context} failed because ${message}`,
    } as StoryError);
  }

  count(message: NotificationMessage): Observable<NotificationCounts> {
    const counts: BehaviorSubject<NotificationCounts> = new BehaviorSubject<NotificationCounts>({ total: 0, read: 0, unread: 0 });
    const obs = this.apiService.get(`/notifications/${message._id}/counts`);

    obs.subscribe(
      newCounts => {
        counts.next(newCounts);
      },
      error => {
        this.setLastError('Retreiving Notification Counts', error.message);
      }
    );
    return counts.asObservable();
  }

  send(message: NotificationMessage): Observable<NotificationMessage> {
    const sentMessage: BehaviorSubject<NotificationMessage> = new BehaviorSubject({} as NotificationMessage);
    const obs = this.apiService.post('/notifications', message);

    obs.subscribe(newMessage => {
      this._notifications.next(this._notifications.getValue().insert(0, newMessage));
      sentMessage.next(newMessage);
    });

    return sentMessage.asObservable();
  }
}
