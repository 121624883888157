<h2 mat-dialog-title>{{ this.data.title }}</h2>
<mat-dialog-content>
  <form [formGroup]="textEntryForm">
    <div fxLayout="row" fxLayoutAlign="center center">
      <mat-form-field fxFlex="100%">
        <input type="text" matInput aria-label="input value" placeholder="{{ this.data.label }}" formControlName="item" />
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="primary" [mat-dialog-close]="textEntryForm.value" [disabled]="textEntryForm.invalid">
    Select
  </button>
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
