import { Component, Inject, OnInit, SecurityContext } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

export interface ErrorDialogData {
  title: string;
  message: string;
}

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
})

export class ErrorDialogComponent implements OnInit {

  public title: string;
  public message: string;

  constructor(
    private dom: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) private data: ErrorDialogData
  ) {
    this.title = data.title || 'Error';
    this.message = data.message ? this.dom.sanitize(SecurityContext.HTML, data.message) : 'An error occured processing your request.';
  }

  ngOnInit() {
  }

}
