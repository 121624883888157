import { BehaviorSubject, Subject } from 'rxjs';
import { List } from 'immutable';
import { finalize } from 'rxjs/operators';
import { ApiService } from './api.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
export class NotificationStoreService {
    constructor(apiService) {
        this.apiService = apiService;
        this._notifications = new BehaviorSubject(List([]));
        this._fetchingNotifications = false;
        this._fetched = false;
        this._lastError = new Subject();
        this.lastError$ = this._lastError.asObservable();
    }
    get notifications$() {
        if (!this._fetchingNotifications && !this._fetched) {
            this._fetchingNotifications = true;
            this.apiService
                .get('/notifications')
                .pipe(finalize(() => {
                this._fetchingNotifications = false;
                this._fetched = true;
            }))
                .subscribe(notifications => {
                this._notifications.next(List(notifications));
            }, error => {
                this.setLastError('Retreiving Notifications', error.message);
            });
        }
        return this._notifications.asObservable();
    }
    setLastError(context, message, story) {
        this._lastError.next({
            context: context,
            message: message,
            story: story,
            formatted: `${context} failed because ${message}`,
        });
    }
    count(message) {
        const counts = new BehaviorSubject({ total: 0, read: 0, unread: 0 });
        const obs = this.apiService.get(`/notifications/${message._id}/counts`);
        obs.subscribe(newCounts => {
            counts.next(newCounts);
        }, error => {
            this.setLastError('Retreiving Notification Counts', error.message);
        });
        return counts.asObservable();
    }
    send(message) {
        const sentMessage = new BehaviorSubject({});
        const obs = this.apiService.post('/notifications', message);
        obs.subscribe(newMessage => {
            this._notifications.next(this._notifications.getValue().insert(0, newMessage));
            sentMessage.next(newMessage);
        });
        return sentMessage.asObservable();
    }
}
NotificationStoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationStoreService_Factory() { return new NotificationStoreService(i0.ɵɵinject(i1.ApiService)); }, token: NotificationStoreService, providedIn: "root" });
