<aside class="">
  <div class="sidebar-card"></div>
  <mat-nav-list class="">
    <mat-list-item class="">
      <a routerLink="/" routerLinkActive="active">
        <mat-icon>home</mat-icon>
        <span class="sidebar-label">Home</span></a
      >
    </mat-list-item>
    <mat-list-item routerLinkActive="active">
      <a [class.disabled]="!auth.hasPerm(Perms.REPORTS)" routerLink="/reports">
        <mat-icon>insert_chart</mat-icon>
        <span class="sidebar-label">Reports</span></a
      >
    </mat-list-item>
    <mat-nav-list dense *ngIf="router.url.startsWith('/reports')" class="sidebar-submenu">
      <h3 matSubheader class="sidebar-submenu-title">Scans</h3>
      <mat-list-item routerLinkActive="active">
        <a routerLink="/reports/scans/{{ ReportTypes.PRODUCER }}">
          <span class="sidebar-label">Producers</span>
        </a>
      </mat-list-item>
      <mat-list-item routerLinkActive="active">
        <a routerLink="/reports/scans/{{ ReportTypes.BEVERAGE }}">
          <span class="sidebar-label">Wines / Spirits</span>
        </a>
      </mat-list-item>
      <mat-list-item routerLinkActive="active">
        <a routerLink="/reports/scans/{{ ReportTypes.TASTING }}">
          <span class="sidebar-label">Producer Tastings</span>
        </a>
      </mat-list-item>
      <mat-list-item routerLinkActive="active">
        <a routerLink="/reports/scans/{{ ReportTypes.PROMO }}">
          <span class="sidebar-label">Producer Promotions</span>
        </a>
      </mat-list-item>
      <mat-list-item routerLinkActive="active">
        <a routerLink="/reports/scans/{{ ReportTypes.MERCHANT }}">
          <span class="sidebar-label">Merchants</span>
        </a>
      </mat-list-item>
      <mat-list-item routerLinkActive="active">
        <a routerLink="/reports/scans/{{ ReportTypes.MERCH_PRODUCT }}">
          <span class="sidebar-label">Merchant Products</span>
        </a>
      </mat-list-item>
      <mat-list-item routerLinkActive="active">
        <a routerLink="/reports/scans/{{ ReportTypes.MERCH_TASTING }}">
          <span class="sidebar-label">Merchant Tastings</span>
        </a>
      </mat-list-item>
      <mat-list-item routerLinkActive="active">
        <a routerLink="/reports/scans/{{ ReportTypes.MERCH_PROMO }}">
          <span class="sidebar-label">Merchant Promotions</span>
        </a>
      </mat-list-item>
      <mat-list-item routerLinkActive="active">
        <a routerLink="/reports/scans/{{ ReportTypes.EVENT }}">
          <span class="sidebar-label">Events</span>
        </a>
      </mat-list-item>
    </mat-nav-list>
    <div *ngIf="selectedWinery$ | async as selectedWinery">
      <mat-list-item routerLinkActive="active">
        <a routerLink="/cms/wineries/{{ selectedWinery._id }}">
          <mat-icon *ngIf="selectedWinery.category === 'winery'">wine_bar</mat-icon>
          <mat-icon *ngIf="selectedWinery.category === 'distillery'">local_bar</mat-icon>
          <span class="sidebar-label">{{ selectedWinery.name }}</span></a
        >
      </mat-list-item>
      <mat-nav-list dense class="sidebar-submenu">
        <h3 *ngIf="selectedWinery.wines && selectedWinery.wines.length > 0" matSubheader class="sidebar-submenu-title">
          <span class="sidebar-label">Wines</span>
        </h3>
        <mat-list-item *ngFor="let wine of selectedWinery.wines" routerLinkActive="active">
          <a routerLink="/cms/wineries/{{ selectedWinery._id }}/wines/{{ wine._id }}"> {{ wine.vintage }} {{ wine.name }} </a>
        </mat-list-item>
        <h3 *ngIf="selectedWinery.spirits && selectedWinery.spirits.length > 0" matSubheader class="sidebar-submenu-title">Spirits</h3>
        <mat-list-item *ngFor="let spirit of selectedWinery.spirits" routerLinkActive="active">
          <a routerLink="/cms/wineries/{{ selectedWinery._id }}/spirits/{{ spirit._id }}">
            <span class="sidebar-label">{{ spirit.name }}</span>
          </a>
        </mat-list-item>
        <h3 *ngIf="selectedWinery.menu && selectedWinery.menu.length > 0" matSubheader class="sidebar-submenu-title">
          Tastings
        </h3>
        <mat-list-item *ngFor="let tasting of selectedWinery.menu; let i = index" routerLinkActive="active">
          <a routerLink="/cms/wineries/{{ selectedWinery._id }}/tastings/{{ i }}">
            <span class="sidebar-label">{{ tasting.name }}</span>
          </a>
        </mat-list-item>
      </mat-nav-list>
    </div>
    <div *ngIf="selectedMerchant$ | async as selectedMerchant">
      <mat-list-item routerLinkActive="active">
        <a routerLink="/cms/merchants/{{ selectedMerchant._id }}">
          <mat-icon *ngIf="selectedMerchant.category === 'restaurant'">restaurant_menu</mat-icon>
          <mat-icon *ngIf="selectedMerchant.category === 'shop'">store_front</mat-icon>
          <mat-icon *ngIf="selectedMerchant.category === 'distributor'">local_shipping</mat-icon>
          <span class="sidebar-label">{{ selectedMerchant.name }}</span></a
        >
      </mat-list-item>
      <mat-nav-list dense class="sidebar-submenu">
        <h3 *ngIf="selectedMerchant.menu && selectedMerchant.menu.length > 0" matSubheader class="sidebar-submenu-title">Tastings</h3>
        <mat-list-item *ngFor="let tasting of selectedMerchant.menu; let i = index" routerLinkActive="active">
          <a routerLink="/cms/merchants/{{ selectedMerchant._id }}/tastings/{{ i }}">
            <span class="sidebar-label">{{ tasting.name }}</span>
          </a>
        </mat-list-item>
      </mat-nav-list>
    </div>
    <div *ngIf="selectedEvent$ | async as selectedEvent">
      <mat-list-item routerLinkActive="active">
        <a routerLink="/cms/events/{{ selectedEvent._id }}">
          <mat-icon>festival</mat-icon>
          <span class="sidebar-label">{{ selectedEvent.name }}</span></a
        >
      </mat-list-item>
    </div>
    <div *ngIf="selectedApp$ | async as selectedApp">
      <mat-list-item routerLinkActive="active">
        <a routerLink="/cms/apps/{{ selectedApp._id }}">
          <mat-icon>phone_android</mat-icon>
          <span class="sidebar-label">{{ selectedApp.settings.appName }}</span></a
        >
      </mat-list-item>
    </div>
  </mat-nav-list>
</aside>
