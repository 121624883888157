/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./event-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../content-card/content-card.component.ngfactory";
import * as i3 from "../content-card/content-card.component";
import * as i4 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@angular/flex-layout/flex";
import * as i9 from "@angular/flex-layout/core";
import * as i10 from "@angular/common";
import * as i11 from "./event-list.component";
import * as i12 from "@angular/material/dialog";
var styles_EventListComponent = [i0.styles];
var RenderType_EventListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventListComponent, data: {} });
export { RenderType_EventListComponent as RenderType_EventListComponent };
function View_EventListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-content-card", [["class", "event-list-card"]], null, [[null, "action"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("action" === en)) {
        var pd_0 = (_co.eventAction(_v.context.index, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ContentCardComponent_0, i2.RenderType_ContentCardComponent)), i1.ɵdid(1, 114688, null, 0, i3.ContentCardComponent, [], { title: [0, "title"], subTitle: [1, "subTitle"], image: [2, "image"], buttons: [3, "buttons"] }, { action: "action" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.name; var currVal_1 = _co.subtitle(_v.context.$implicit); var currVal_2 = _co.eventImg(_v.context.$implicit); var currVal_3 = _co.buttons; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_EventListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Add New Event"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_EventListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.insertEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Insert Event"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_EventListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i8.DefaultLayoutDirective, [i1.ElementRef, i9.StyleUtils, [2, i8.LayoutStyleBuilder], i9.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "content-container"], ["id", "event-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventListComponent_1)), i1.ɵdid(4, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "span", [["class", "button-row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventListComponent_2)), i1.ɵdid(7, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventListComponent_3)), i1.ɵdid(9, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.events; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.canCreate; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.canInsert; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_EventListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-event-list", [], null, null, null, View_EventListComponent_0, RenderType_EventListComponent)), i1.ɵdid(1, 114688, null, 0, i11.EventListComponent, [i12.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventListComponentNgFactory = i1.ɵccf("app-event-list", i11.EventListComponent, View_EventListComponent_Host_0, { appId: "appId", events: "events", canCreate: "canCreate", canEdit: "canEdit", canRemove: "canRemove", canInsert: "canInsert" }, { action: "action" }, []);
export { EventListComponentNgFactory as EventListComponentNgFactory };
