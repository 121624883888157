<div class="page">
  <div *ngIf="!auth.loggedIn" class="banner">
    <div class="container">
      <h2 class="logo-font">BottleVin</h2>
      <p>The future of selling, marketing and identifying your wine.</p>
    </div>
  </div>

  <div *ngIf="auth.loggedIn" fxLayout="row" fxLayoutGap="20px">
    <div *ngIf="wineryStore.settings$ | async as settings" fxFlex="100%">
      <mat-tab-group [(selectedIndex)]="tabIndex" (animationDone)="animationDone()">
        <mat-tab *ngIf="settings.perms?.supportedTypes.includes(AppTypes.WINERY) || settings.appType === AppTypes.WINERY" label="Wineries">
          <app-producer-list
            category="winery"
            [producers]="wineries$ | async"
            [rawProducers]="wineriesRaw$ | async"
            [filter]="wineryStore.wineryFilter"
            [canCreate]="auth.isAdmin() || (auth.appAdmin() && settings.perms?.createTypes.includes(AppTypes.WINERY))"
            (action)="producerAction($event)"
          ></app-producer-list>
        </mat-tab>
        <mat-tab
          *ngIf="settings.perms?.supportedTypes.includes(AppTypes.DISTILLERY) || settings.appType === AppTypes.DISTILLERY"
          label="Distilleries"
        >
          <app-producer-list
            category="distillery"
            [producers]="distilleries$ | async"
            [rawProducers]="distilleriesRaw$ | async"
            [filter]="wineryStore.wineryFilter"
            [canCreate]="auth.isAdmin() || (auth.appAdmin() && settings.perms?.createTypes.includes(AppTypes.DISTILLERY))"
            (action)="producerAction($event)"
          ></app-producer-list>
        </mat-tab>
        <mat-tab
          *ngIf="settings.perms?.supportedTypes.includes(AppTypes.RESTAURANT) || settings.appType === AppTypes.RESTAURANT"
          label="Restaurants"
        >
          <app-merchant-list
            category="restaurant"
            [merchants]="restaurants$ | async"
            [rawMerchants]="restaurantsRaw$ | async"
            [filter]="merchantStore.merchantFilter"
            [canCreate]="auth.isAdmin() || (auth.appAdmin() && settings.perms?.createTypes.includes(AppTypes.RESTAURANT))"
            (action)="merchantAction($event)"
          ></app-merchant-list>
        </mat-tab>
        <mat-tab *ngIf="settings.perms?.supportedTypes.includes(AppTypes.SHOP) || settings.appType === AppTypes.SHOP" label="Shops">
          <app-merchant-list
            category="shop"
            [merchants]="shops$ | async"
            [rawMerchants]="shopsRaw$ | async"
            [filter]="merchantStore.merchantFilter"
            [canCreate]="auth.isAdmin() || (auth.appAdmin() && settings.perms?.createTypes.includes(AppTypes.SHOP))"
            (action)="merchantAction($event)"
          ></app-merchant-list>
        </mat-tab>
        <mat-tab
          *ngIf="settings.perms?.supportedTypes.includes(AppTypes.DISTRIBUTOR) || settings.appType === AppTypes.DISTRIBUTOR"
          label="Distributors"
        >
          <app-merchant-list
            category="distributor"
            [merchants]="distributors$ | async"
            [rawMerchants]="distributorsRaw$ | async"
            [filter]="merchantStore.merchantFilter"
            [canCreate]="auth.isAdmin() || (auth.appAdmin() && settings.perms?.createTypes.includes(AppTypes.DISTRIBUTOR))"
            (action)="merchantAction($event)"
          ></app-merchant-list>
        </mat-tab>
        <mat-tab
          *ngIf="
            (auth.isAdmin() || auth.appAdmin()) &&
            (settings.perms?.supportedTypes.includes(AppTypes.EVENT) || settings.appType === AppTypes.EVENT)
          "
          label="Events"
        >
          <app-event-list
            [appId]="settings._id"
            [events]="this.eventService.events$ | async"
            [canCreate]="auth.isAdmin() || (auth.appAdmin() && settings.perms?.createTypes.includes(AppTypes.EVENT))"
            (action)="eventsAction($event)"
          ></app-event-list>
        </mat-tab>
        <mat-tab
          *ngIf="
            (auth.isAdmin() || auth.appAdmin()) &&
            (settings.perms?.supportedTypes.includes(AppTypes.PROMO) || settings.appType === AppTypes.PROMO)
          "
          label="Promotions"
        >
          <app-promo-list
            [promos]="(promoService.promos$ | async).toArray()"
            [settings]="settings"
            (action)="promoAction($event)"
          ></app-promo-list>
        </mat-tab>
        <mat-tab *ngIf="auth.isAdmin()" label="Apps">
          <app-app-list [apps]="(appService.apps$ | async).toArray()" (action)="appAction($event)"></app-app-list>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
