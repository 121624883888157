import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Constants, Utils } from '../../scripts';

export interface NewWineryDialogData {
  category: string;
}

@Component({
  selector: 'app-new-winery-dialog',
  templateUrl: './new-winery-dialog.component.html',
  styleUrls: ['./new-winery-dialog.component.scss'],
})
export class NewWineryDialogComponent implements OnInit {
  public category: string;

  constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) private data: NewWineryDialogComponent) {
    this.category = data.category || Constants.ProdCategory.WINERY;
  }

  newWineryForm = this.fb.group({
    name: ['', Utils.notEmpty],
    established: [''],
  });

  ngOnInit() {}
}
