<span *ngIf="auth.isAdmin()">
  <button
    type="button"
    mat-icon-button
    class="product-upload-button"
    matTooltip="Upload tags for this {{contextName}}"
    (click)="openUpload(ident)"
    [ngClass]="{'disabled': uploader.isUploading}"
  >
    <mat-icon svgIcon="nfc"></mat-icon>
  </button>
  <input type="file" id="{{ident}}" (change)="uploadTags($event)" style="display: none;" [disabled]="uploader.isUploading">
</span>
