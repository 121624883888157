import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Utils } from '../../scripts';

export interface TextEntryData {
  title: String;
  label: String;
  value?: String;
}

@Component({
  selector: 'app-text-entry-dialog',
  templateUrl: './text-entry-dialog.component.html',
  styleUrls: [],
})
export class TextEntryDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: TextEntryData, private fb: FormBuilder) {}

  selectMenu = [];
  textEntryForm = this.fb.group({
    item: ['', [Utils.notEmpty]],
  });

  ngOnInit() {
    if (this.data.value) {
      this.textEntryForm.get('item').setValue(this.data.value);
    }
  }
}
