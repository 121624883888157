<h2 mat-dialog-title>Add New {{ category | titlecase }}</h2>
<mat-dialog-content>
  <form [formGroup]="newWineryForm">
    <div fxLayout="row" fxLayoutGap="10px">
      <mat-form-field fxFlex="75%">
        <input matInput aria-label="Name" placeholder="Name" id="winery-name" type="text" formControlName="name" />
      </mat-form-field>
      <mat-form-field fxFlex="25%">
        <input
          matInput
          aria-label="Established"
          placeholder="Established"
          id="winery-established"
          type="number"
          formControlName="established"
        />
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [disabled]="newWineryForm.invalid" [mat-dialog-close]="newWineryForm.value">Create</button>
</mat-dialog-actions>
