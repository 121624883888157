<mat-card class="wine-card">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="10%" cdkDragHandle>
      <img class="wine-img list" [src]="image" alt="wine" />
    </div>
    <div fxFlex="90%">
      <mat-card-content fxLayout="row" fxLayoutGap="5px">
        <div fxFlex="70%" class="drag-grab-area" cdkDragHandle>
          <mat-card-title>{{ title }}</mat-card-title>
          <mat-card-subtitle>{{ subTitle }}</mat-card-subtitle>
        </div>
        <div fxFlex="30%" fxLayout="row" fxLayoutGap="3px" fxLayoutAlign="end">
          <span *ngFor="let button of buttons" fxFlex="25%">
            <button mat-icon-button matTooltip="{{ button.tip }}" (click)="buttonAction(button.name)">
              <mat-icon>{{ button.icon }}</mat-icon>
            </button>
          </span>
        </div>
      </mat-card-content>
    </div>
  </div>
</mat-card>
