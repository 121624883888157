import { BehaviorSubject, Subject } from 'rxjs';
import { List } from 'immutable';
import { ApiService } from './api.service';
import { finalize } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
export class EventsService {
    constructor(apiService) {
        this.apiService = apiService;
        this._events$ = new BehaviorSubject(List([]));
        this._fetchingEvents = false;
        this._fetched = false;
        this._fetchingDocs = false;
        this._docs$ = new BehaviorSubject(List([]));
        this._lastError = new Subject();
        this.lastError$ = this._lastError.asObservable();
    }
    get events$() {
        if (!this._fetchingEvents && !this._fetched) {
            this._fetchingEvents = true;
            this.apiService
                .get('/events?past=true')
                .pipe(finalize(() => {
                this._fetchingEvents = false;
                this._fetched = true;
            }))
                .subscribe(events => {
                this._events$.next(List(events));
            }, error => {
                this.setLastError('Retrieving events', error.message);
            });
        }
        return this._events$.asObservable();
    }
    setLastError(context, message, event) {
        this._lastError.next({
            context: context,
            message: message,
            event: event,
            formatted: `${context} failed because ${message}`,
        });
    }
    updateEventInList(event) {
        const _events = this._events$.getValue();
        const index = _events.findIndex(w => w._id === event._id);
        this._events$.next(_events.set(index, event));
    }
    refetchEvents() {
        this._events$.next(List([]));
        if (!this._fetchingEvents) {
            this._fetchingEvents = true;
            this.apiService
                .get('/events?past=true')
                .pipe(finalize(() => {
                this._fetchingEvents = false;
                this._fetched = true;
            }))
                .subscribe(events => {
                this._events$.next(List(events));
            }, error => {
                this.setLastError('Retrieving events', error.message);
            });
        }
        return this._events$.asObservable();
    }
    addEvent(event) {
        const returnedEvent = new BehaviorSubject(event);
        this.apiService.post('/events', event).subscribe(newEvent => {
            returnedEvent.next(newEvent);
            this._events$.next(this._events$.getValue().insert(0, newEvent));
        }, error => {
            this.setLastError('Adding Event', error.message, event);
        });
        return returnedEvent.asObservable();
    }
    deleteEvent(event) {
        const returnedEvent = new BehaviorSubject(event);
        const events = this._events$.getValue();
        const index = events.findIndex(s => s._id === event._id);
        if (index === -1) {
            this.setLastError('Deleting Event', 'event not in list', event);
        }
        else {
            this.apiService.delete(`/events/${event._id}`).subscribe(() => {
                returnedEvent.next({});
                this._events$.next(events.splice(index, 1));
            }, error => {
                this.setLastError('Deleting event', error.message, event);
            });
        }
        return returnedEvent.asObservable();
    }
    fetchEvent(eventId, force = false) {
        const fetchedEvent$ = new BehaviorSubject({});
        const _events = this._events$.getValue();
        const _eventDetail = _events.find(w => w._id === eventId);
        if (!force && _eventDetail && Array.isArray(_eventDetail.products)) {
            // Already have a full event in the list just return it
            fetchedEvent$.next(_eventDetail);
        }
        else {
            this.apiService.get(`/events/${eventId}`).subscribe(eventDetail => {
                this.updateEventInList(eventDetail);
                fetchedEvent$.next(eventDetail);
            }, error => {
                this.setLastError('Fetching Event', error.message);
            });
        }
        return fetchedEvent$.asObservable();
    }
    saveEventDetail(event) {
        const _events = this._events$.getValue();
        const index = _events.findIndex(s => s._id === event._id);
        const event$ = new BehaviorSubject(_events.get(index));
        if (index === -1) {
            this.setLastError('Saving Event', 'event not in list', event);
        }
        else {
            this.apiService.put(`/events/${event._id}`, event).subscribe(savedEvent => {
                this.updateEventInList(savedEvent);
                event$.next(savedEvent);
            }, error => {
                this.setLastError('Saving Event', error.message, event);
            });
        }
        return event$.asObservable();
    }
    addEventProduct(event, product) {
        const event$ = new BehaviorSubject(event);
        this.apiService.post(`/events/${event._id}/products`, product).subscribe(savedEvent => {
            this.updateEventInList(savedEvent);
            event$.next(savedEvent);
        });
        return event$.asObservable();
    }
    removeEventProduct(event, productId) {
        const event$ = new BehaviorSubject(event);
        this.apiService.delete(`/events/${event._id}/products/${productId}`).subscribe(savedEvent => {
            this.updateEventInList(savedEvent);
            event$.next(savedEvent);
        });
        return event$.asObservable();
    }
    updateEventProduct(event, product) {
        const event$ = new BehaviorSubject(event);
        this.apiService.put(`/events/${event._id}/products/${product._id}`, product).subscribe(savedEvent => {
            this.updateEventInList(savedEvent);
            event$.next(savedEvent);
        });
        return event$.asObservable();
    }
    getDocs(event) {
        if (event.docs.length === 0 || typeof event.docs[0] === 'object') {
            console.log(`Returning cached docs for ${event._id}`);
            const docs = event.docs;
            this._docs$.next(List(docs));
        }
        else if (!this._fetchingDocs) {
            this._fetchingDocs = true;
            console.log(`Fetching docs for ${event._id}`);
            this.apiService.get(`/events/${event._id}/docs`).subscribe(docs => {
                event.docs = docs;
                this.updateEventInList(event);
                this._docs$.next(List(docs));
            }, error => {
                this.setLastError('Fetching Docs', error.message, event);
            }, () => {
                this._fetchingDocs = false;
            });
        }
        return this._docs$.asObservable();
    }
    addDocList(event, data) {
        this.apiService.post(`/events/${event._id}/docs`, data).subscribe(updatedEvent => {
            this.updateEventInList(updatedEvent);
            this._docs$.next(List(updatedEvent.docs));
        }, error => {
            this.setLastError('Adding Doc List', error.message, event);
        });
        return this._docs$.asObservable();
    }
    updateDocList(event, data) {
        this.apiService.put(`/events/${event._id}/docs/${data._id}`, data).subscribe(updatedEvent => {
            this.updateEventInList(updatedEvent);
            this._docs$.next(List(updatedEvent.docs));
        }, error => {
            this.setLastError('Editing Doc List', error.message, event);
        });
        return this._docs$.asObservable();
    }
    deleteDocList(event, data) {
        this.apiService.delete(`/events/${event._id}/docs/${data._id}`).subscribe(updatedEvent => {
            this.updateEventInList(updatedEvent);
            this._docs$.next(List(updatedEvent.docs));
        }, error => {
            this.setLastError('Removing Doc List', error.message, event);
        });
        return this._docs$.asObservable();
    }
    updateEventDocs(event, data) {
        const docs$ = new BehaviorSubject(List(data));
        const docIds = data.map(d => d._id);
        this.apiService.put(`/events/${event._id}`, Object.assign({}, event, { docs: docIds })).subscribe(updatedEvent => {
            this.updateEventInList(updatedEvent);
            docs$.next(List(updatedEvent.docs));
        }, error => {
            this.setLastError(`Saving event`, error.message, event);
        });
        return docs$.asObservable();
    }
}
EventsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventsService_Factory() { return new EventsService(i0.ɵɵinject(i1.ApiService)); }, token: EventsService, providedIn: "root" });
