var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EventEmitter, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { List } from 'immutable';
import { NewEventDialogComponent } from '../dialogs';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../dialogs';
import { EventsOp } from '../../scripts';
import * as moment from 'moment';
export class EventListComponent {
    constructor(dialog) {
        this.dialog = dialog;
        this.canEdit = true;
        this.canRemove = true;
        this.canInsert = false;
        this.action = new EventEmitter();
    }
    set events(events) {
        if (!this.appId) {
            this._events = events.filter(e => !e.appId);
        }
        else {
            this._events = events;
        }
    }
    get events() {
        return this._events;
    }
    ngOnInit() {
        this.buttons = [];
        if (this.canEdit) {
            this.buttons.push({ name: 'edit', icon: 'edit', tip: `Edit Event details` }, { name: 'copy', icon: 'file_copy', tip: `Copy details to new Event` });
        }
        if (this.canRemove) {
            this.buttons.push({ name: 'delete', icon: 'delete', tip: `Remove Event` });
        }
    }
    subtitle(event) {
        if (!event.start) {
            return '';
        }
        return event.timeOpt ? `Start: ${moment(event.start).format('LL')}` : `Start: ${moment(event.start).format('LLL')}`;
    }
    eventImg(event) {
        const image = event.thumb || event.image;
        if (!image) {
            return 'assets/winery.svg';
        }
        else if (image.startsWith('http')) {
            return image;
        }
        else {
            return `${environment.content_url}/${image}`;
        }
    }
    eventAction(index, button) {
        return __awaiter(this, void 0, void 0, function* () {
            switch (button) {
                case 'edit':
                    yield this.editEvent(index);
                    break;
                case 'copy':
                    yield this.copyEvent(index);
                    break;
                case 'delete':
                    this.removeEvent(index);
                    break;
            }
        });
    }
    copyEvent(index) {
        return __awaiter(this, void 0, void 0, function* () {
            const event = this.events.get(index);
            const dialogRef = this.dialog.open(NewEventDialogComponent, {
                width: '500px',
                data: {
                    event: event,
                },
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    const eventDate = moment(result.eventDate);
                    if (result.eventTime) {
                        const eventTime = moment(result.eventTime, 'LT');
                        eventDate.add(eventTime.hours(), 'hours');
                        eventDate.add(eventTime.minutes(), 'minutes');
                    }
                    delete result.eventDate;
                    delete result.eventTime;
                    result.start = eventDate;
                    const eventData = Object.assign({}, event, result, { _id: undefined });
                    this.action.emit([EventsOp.AddEvent, eventData]);
                }
            });
        });
    }
    editEvent(index) {
        return __awaiter(this, void 0, void 0, function* () {
            const event = this.events.get(index);
            this.action.emit([EventsOp.EditEvent, event]);
        });
    }
    insertEvent() {
        this.action.emit([EventsOp.AddEvent, null]);
    }
    addEvent() {
        const dialogRef = this.dialog.open(NewEventDialogComponent, {
            width: '500px',
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const eventDate = moment(result.eventDate);
                if (result.eventTime) {
                    const eventTime = moment(result.eventTime, 'LT');
                    eventDate.add(eventTime.hours(), 'hours');
                    eventDate.add(eventTime.minutes(), 'minutes');
                }
                else {
                    result.timeOpt = true;
                }
                delete result.eventDate;
                delete result.eventTime;
                result.start = eventDate;
                this.action.emit([EventsOp.AddEvent, result]);
            }
        });
    }
    removeEvent(index) {
        const event = this.events.get(index);
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: 'Delete Event',
                message: `<p>This will remove the event '${event.name}' ` +
                    `but will not permenantly remove it from the system.</p>` +
                    `<p>Are you sure you want to do this?</p>`,
            },
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.action.emit([EventsOp.RemoveEvent, event]);
            }
        });
    }
}
