import { NgModule } from '@angular/core';

import { HomeComponent } from './home.component';
import { HomeAuthResolver } from './home-auth-resolver.service';
import { MaterialModule, SharedModule } from '../shared';
import { HomeRoutingModule } from './home-routing.module';
import { NewWineryDialogComponent } from './new-winery-dialog/new-winery-dialog.component';
import { NewMerchantDialogComponent } from './new-merchant-dialog/new-merchant-dialog.component';
import { AppListComponent } from './app-list/app-list.component';
import { NewAppDialogComponent } from './new-app-dialog/new-app-dialog.component';

@NgModule({
  imports: [SharedModule, MaterialModule, HomeRoutingModule],
  declarations: [NewWineryDialogComponent, HomeComponent, NewMerchantDialogComponent, AppListComponent, NewAppDialogComponent],
  entryComponents: [NewWineryDialogComponent, NewMerchantDialogComponent, NewAppDialogComponent],
  providers: [HomeAuthResolver],
})
export class HomeModule {}
