import { BehaviorSubject, Subject } from 'rxjs';
import { List } from 'immutable';
import { ApiService } from './api.service';
import { finalize } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
export class FeaturesStoreService {
    constructor(apiService) {
        this.apiService = apiService;
        this._stories = new BehaviorSubject(List([]));
        this._fetchingStories = false;
        this._fetched = false;
        this._lastError = new Subject();
        this.lastError$ = this._lastError.asObservable();
    }
    get stories$() {
        if (!this._fetchingStories && !this._fetched) {
            this._fetchingStories = true;
            this.apiService
                .get('/stories?sorted=true')
                .pipe(finalize(() => {
                this._fetchingStories = false;
                this._fetched = true;
            }))
                .subscribe(stories => {
                this._stories.next(List(stories));
            }, error => {
                this.setLastError('Retrieving stories', error.message);
            });
        }
        return this._stories.asObservable();
    }
    setLastError(context, message, story) {
        this._lastError.next({
            context: context,
            message: message,
            story: story,
            formatted: `${context} failed because ${message}`,
        });
    }
    updateStories(stories) {
        const storyIds = stories.map(s => s._id);
        this.apiService.put('/stories/app', { stories: storyIds }).subscribe(savedStories => {
            this._stories.next(List(savedStories));
        }, error => {
            this.setLastError('Updating stories', error.message);
        });
        return this.stories$;
    }
    addStory(story) {
        const returnedStory = new BehaviorSubject(story);
        this.apiService.post('/stories', story).subscribe(newStory => {
            returnedStory.next(newStory);
            this._stories.next(this._stories.getValue().insert(0, newStory));
        }, error => {
            this.setLastError('Adding story', error.message, story);
        });
        return returnedStory.asObservable();
    }
    saveStory(story) {
        const returnedStory = new BehaviorSubject(story);
        const stories = this._stories.getValue();
        const index = stories.findIndex(s => s._id === story._id);
        if (index === -1) {
            this.setLastError('Saving story', 'story not in list', story);
        }
        else {
            this.apiService.put(`/stories/${story._id}`, story).subscribe(savedStory => {
                returnedStory.next(savedStory);
                this._stories.next(stories.splice(index, 1, savedStory));
            }, error => {
                this.setLastError('Saving story', error.message, story);
            });
        }
        return returnedStory.asObservable();
    }
    deleteStory(story) {
        const returnedStory = new BehaviorSubject(story);
        const stories = this._stories.getValue();
        const index = stories.findIndex(s => s._id === story._id);
        if (index === -1) {
            this.setLastError('Deleting story', 'story not in list', story);
        }
        else {
            this.apiService.delete(`/stories/${story._id}`).subscribe(() => {
                returnedStory.next({});
                this._stories.next(stories.splice(index, 1));
            }, error => {
                this.setLastError('Deleting story', error.message, story);
            });
        }
        return returnedStory.asObservable();
    }
}
FeaturesStoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeaturesStoreService_Factory() { return new FeaturesStoreService(i0.ɵɵinject(i1.ApiService)); }, token: FeaturesStoreService, providedIn: "root" });
