const BASE_URL = window.location.origin;
export const environment = {
  production: true,
  api_url: `${BASE_URL}/v1`,
  content_url: `${BASE_URL}/content`,
  auth: {
    CLIENT_ID: 'Xv4eMc87ty5LrV1ey8EoPlD-IO6P-LwB',
    CLIENT_DOMAIN: 'bottlevin.auth0.com',
    AUDIENCE: 'https://app.bottlevin.com/',
    REDIRECT: `${BASE_URL}/callback`,
    SCOPE: 'openid profile email address phone',
    LOGOUT_URL: `${BASE_URL}/`,
  },
};
