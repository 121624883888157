import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, AuthService } from '../core/services';

@Component({
  selector: 'app-zoom-auth',
  templateUrl: './zoom-auth.component.html',
  styleUrls: ['./zoom-auth.component.scss'],
})
export class ZoomAuthComponent implements OnInit {
  constructor(private api: ApiService, private auth: AuthService, private router: Router) {}

  ngOnInit() {
    this.auth.loggedIn$.subscribe(loggedIn => {
      if (loggedIn) {
        const urlTree = this.router.parseUrl(this.router.url);
        const authCode = urlTree.queryParams['code'];
        const stateVal = urlTree.queryParams['state'];
        const { modelType, modelId } = JSON.parse(stateVal);
        this.api.put(`/${modelType}/${modelId}/meeting/auth`, { authCode }).subscribe(({ success }) => {
          if (success) {
            this.router.navigateByUrl(`/cms/${modelType}/${modelId}`);
          }
        });
      }
    });
  }
}
