import { BehaviorSubject, Subject } from 'rxjs';
import { App, AppDetail } from '../models';
import { List } from 'immutable';
import { ApiService } from './api.service';
import { finalize } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
export class AppsService {
    constructor(apiService) {
        this.apiService = apiService;
        this._apps$ = new BehaviorSubject(List([]));
        this._fetchingApps = false;
        this._fetched = false;
        this._lastError = new Subject();
        this.lastError$ = this._lastError.asObservable();
    }
    get apps$() {
        if (!this._fetchingApps && !this._fetched) {
            this._fetchingApps = true;
            this.apiService
                .get('/apps')
                .pipe(finalize(() => {
                this._fetchingApps = false;
                this._fetched = true;
            }))
                .subscribe(apps => {
                this._apps$.next(List(apps));
            }, error => {
                this.setLastError('Retrieving apps', error.message);
            });
        }
        return this._apps$.asObservable();
    }
    setLastError(context, message, app) {
        this._lastError.next({
            context,
            message,
            app,
            formatted: `${context} failed because ${message}`,
        });
    }
    updateAppInList(app) {
        const _apps = this._apps$.getValue();
        const index = _apps.findIndex(w => w._id === app._id);
        this._apps$.next(_apps.set(index, app));
    }
    addApp(app) {
        const returnedPromo = new BehaviorSubject(app);
        this.apiService.post('/apps', app).subscribe(newApp => {
            returnedPromo.next(newApp);
            this._apps$.next(this._apps$.getValue().insert(0, newApp));
        }, error => {
            this.setLastError('Adding App', error.message, app);
        });
        return returnedPromo.asObservable();
    }
    deleteApp(app) {
        const returnedApp = new BehaviorSubject(app);
        const apps = this._apps$.getValue();
        const index = apps.findIndex(s => s._id === app._id);
        if (index === -1) {
            this.setLastError('Deleting App', 'promo not in list', app);
        }
        else {
            this.apiService.delete(`/apps/${app._id}`).subscribe(() => {
                returnedApp.next({});
                this._apps$.next(apps.splice(index, 1));
            }, error => {
                this.setLastError('Deleting promo', error.message, app);
            });
        }
        return returnedApp.asObservable();
    }
    fetchApp(appId, force = false) {
        const fetchedApp$ = new BehaviorSubject({});
        const apps = this._apps$.getValue();
        const app = apps.find(w => w._id === appId);
        if (app) {
            const appDetail = app;
            if (!force && appDetail.settings.perms) {
                fetchedApp$.next(appDetail);
            }
            else {
                this.apiService.get(`/apps/${appId}`).subscribe(appData => {
                    this.updateAppInList(appData);
                    fetchedApp$.next(appData);
                }, error => {
                    this.setLastError('Fetching App', error.message);
                });
            }
        }
        else {
            this.setLastError('Fetching App', `invalid id supplied`);
        }
        return fetchedApp$.asObservable();
    }
    saveApp(app) {
        const _apps = this._apps$.getValue();
        const index = _apps.findIndex(s => s._id === app._id);
        const app$ = new BehaviorSubject(_apps.get(index));
        if (index === -1) {
            this.setLastError('Saving App', 'app not in list', app);
        }
        else {
            this.apiService.put(`/apps/${app._id}`, app).subscribe(savedApp => {
                this.updateAppInList(savedApp);
                app$.next(savedApp);
            }, error => {
                this.setLastError('Saving App', error.message, app);
            });
        }
        return app$.asObservable();
    }
}
AppsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppsService_Factory() { return new AppsService(i0.ɵɵinject(i1.ApiService)); }, token: AppsService, providedIn: "root" });
