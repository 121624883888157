import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { App, AppDetail, EventDetail } from '../models';
import { List } from 'immutable';
import { ApiService } from './api.service';
import { finalize } from 'rxjs/operators';

export interface AppError {
  context: string;
  message: string;
  app?: App;
  formatted: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppsService {
  private _apps$: BehaviorSubject<List<App>> = new BehaviorSubject(List([]));
  private _fetchingApps = false;
  private _fetched = false;

  private _lastError: Subject<AppError> = new Subject<AppError>();
  public lastError$: Observable<AppError> = this._lastError.asObservable();

  constructor(private apiService: ApiService) {}
  get apps$() {
    if (!this._fetchingApps && !this._fetched) {
      this._fetchingApps = true;
      this.apiService
        .get('/apps')
        .pipe(
          finalize(() => {
            this._fetchingApps = false;
            this._fetched = true;
          })
        )
        .subscribe(
          apps => {
            this._apps$.next(List(apps));
          },
          error => {
            this.setLastError('Retrieving apps', error.message);
          }
        );
    }
    return this._apps$.asObservable();
  }

  private setLastError(context: string, message: string, app?: App) {
    this._lastError.next({
      context,
      message,
      app,
      formatted: `${context} failed because ${message}`,
    } as AppError);
  }

  private updateAppInList(app: App) {
    const _apps = this._apps$.getValue();
    const index = _apps.findIndex(w => w._id === app._id);
    this._apps$.next(_apps.set(index, app));
  }

  addApp(app: App): Observable<App> {
    const returnedPromo: BehaviorSubject<App> = new BehaviorSubject(app);
    this.apiService.post('/apps', app).subscribe(
      newApp => {
        returnedPromo.next(newApp);
        this._apps$.next(this._apps$.getValue().insert(0, newApp));
      },
      error => {
        this.setLastError('Adding App', error.message, app);
      }
    );

    return returnedPromo.asObservable();
  }

  deleteApp(app: App): Observable<App> {
    const returnedApp: BehaviorSubject<App> = new BehaviorSubject(app);
    const apps = this._apps$.getValue();
    const index = apps.findIndex(s => s._id === app._id);
    if (index === -1) {
      this.setLastError('Deleting App', 'promo not in list', app);
    } else {
      this.apiService.delete(`/apps/${app._id}`).subscribe(
        () => {
          returnedApp.next({} as App);
          this._apps$.next(apps.splice(index, 1));
        },
        error => {
          this.setLastError('Deleting promo', error.message, app);
        }
      );
    }

    return returnedApp.asObservable();
  }

  fetchApp(appId: string, force: boolean = false): Observable<AppDetail> {
    const fetchedApp$: BehaviorSubject<AppDetail> = new BehaviorSubject<AppDetail>({} as AppDetail);
    const apps = this._apps$.getValue();
    const app = apps.find(w => w._id === appId);
    if (app) {
      const appDetail = app as AppDetail;
      if (!force && appDetail.settings.perms) {
        fetchedApp$.next(appDetail);
      } else {
        this.apiService.get(`/apps/${appId}`).subscribe(
          appData => {
            this.updateAppInList(appData);
            fetchedApp$.next(appData);
          },
          error => {
            this.setLastError('Fetching App', error.message);
          }
        );
      }
    } else {
      this.setLastError('Fetching App', `invalid id supplied`);
    }
    return fetchedApp$.asObservable();
  }

  saveApp(app: AppDetail): Observable<AppDetail> {
    const _apps = this._apps$.getValue();
    const index = _apps.findIndex(s => s._id === app._id);
    const app$: BehaviorSubject<AppDetail> = new BehaviorSubject<AppDetail>(_apps.get(index) as AppDetail);
    if (index === -1) {
      this.setLastError('Saving App', 'app not in list', app);
    } else {
      this.apiService.put(`/apps/${app._id}`, app).subscribe(
        savedApp => {
          this.updateAppInList(savedApp);
          app$.next(savedApp);
        },
        error => {
          this.setLastError('Saving App', error.message, app);
        }
      );
    }
    return app$.asObservable();
  }
}
