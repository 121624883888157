import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    protected router: Router,
    protected auth: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!AuthService.tokenValid) {
      this.auth.login();
      return false;
    }
    if (!this.auth.loggedIn) {
      localStorage.setItem('authRedirect', state.url);
      return false;
    }
    return true;
  }
}
