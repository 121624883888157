<h2 mat-dialog-title>Add New Event</h2>
<mat-dialog-content>
  <form [formGroup]="newEventForm">
    <div fxLayout="row" fxLayoutGap="10px">
      <mat-form-field fxFlex="100%">
        <input matInput aria-label="Name" placeholder="Name" id="event-name" type="text" formControlName="name" />
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="10px">
      <mat-form-field fxFlex="50%">
        <input
          matInput
          aria-label="Date of the Event"
          placeholder="Date of the Event"
          [matDatepicker]="date"
          [min]="todaysDate"
          formControlName="eventDate"
        />
        <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
        <mat-datepicker #date></mat-datepicker>
      </mat-form-field>
      <mat-form-field fxFlex="50%">
        <input matInput aria-label="Time of the Event" type="time" placeholder="Time of the Event" formControlName="eventTime" />
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="primary" [mat-dialog-close]="newEventForm.value" [disabled]="newEventForm.invalid">Save</button>
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
