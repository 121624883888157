/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tag-upload.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "@angular/flex-layout/extended";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "@angular/material/tooltip";
import * as i10 from "@angular/cdk/overlay";
import * as i11 from "@angular/cdk/scrolling";
import * as i12 from "@angular/cdk/platform";
import * as i13 from "@angular/cdk/bidi";
import * as i14 from "@angular/platform-browser";
import * as i15 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i16 from "@angular/material/icon";
import * as i17 from "./tag-upload.component";
import * as i18 from "../../core/services/auth.service";
import * as i19 from "@angular/material/snack-bar";
var styles_TagUploadComponent = [i0.styles];
var RenderType_TagUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TagUploadComponent, data: {} });
export { RenderType_TagUploadComponent as RenderType_TagUploadComponent };
function View_TagUploadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 9, "button", [["class", "product-upload-button"], ["mat-icon-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 8)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.openUpload(_co.ident) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "disabled": 0 }), i1.ɵdid(5, 933888, null, 0, i4.DefaultClassDirective, [i1.ElementRef, i5.StyleUtils, i5.MediaMarshaller, i3.ɵNgClassImpl, [6, i3.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(6, { "disabled": 0 }), i1.ɵdid(7, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(8, 212992, null, 0, i9.MatTooltip, [i10.Overlay, i1.ElementRef, i11.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i12.Platform, i7.AriaDescriber, i7.FocusMonitor, i9.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i13.Directionality], [2, i9.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i14.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "nfc"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i15.View_MatIcon_0, i15.RenderType_MatIcon)), i1.ɵdid(10, 9158656, null, 0, i16.MatIcon, [i1.ElementRef, i16.MatIconRegistry, [8, null], [2, i16.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 0, "input", [["style", "display: none;"], ["type", "file"]], [[8, "id", 0], [8, "disabled", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.uploadTags($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "product-upload-button"; var currVal_3 = _ck(_v, 4, 0, _co.uploader.isUploading); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = _ck(_v, 6, 0, _co.uploader.isUploading); var currVal_5 = "product-upload-button"; _ck(_v, 5, 0, currVal_4, currVal_5); var currVal_6 = i1.ɵinlineInterpolate(1, "Upload tags for this ", _co.contextName, ""); _ck(_v, 8, 0, currVal_6); var currVal_9 = "nfc"; _ck(_v, 10, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 7).disabled || null); var currVal_1 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_7 = i1.ɵnov(_v, 10).inline; var currVal_8 = (((i1.ɵnov(_v, 10).color !== "primary") && (i1.ɵnov(_v, 10).color !== "accent")) && (i1.ɵnov(_v, 10).color !== "warn")); _ck(_v, 9, 0, currVal_7, currVal_8); var currVal_10 = i1.ɵinlineInterpolate(1, "", _co.ident, ""); var currVal_11 = _co.uploader.isUploading; _ck(_v, 11, 0, currVal_10, currVal_11); }); }
export function View_TagUploadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagUploadComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.auth.isAdmin(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TagUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tag-upload", [], null, null, null, View_TagUploadComponent_0, RenderType_TagUploadComponent)), i1.ɵdid(1, 114688, null, 0, i17.TagUploadComponent, [i18.AuthService, i19.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TagUploadComponentNgFactory = i1.ɵccf("app-tag-upload", i17.TagUploadComponent, View_TagUploadComponent_Host_0, { ident: "ident", context: "context", contextId: "contextId" }, { progress: "progress" }, []);
export { TagUploadComponentNgFactory as TagUploadComponentNgFactory };
