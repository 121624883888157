<h2 mat-dialog-title>Commerce7 Import</h2>
<mat-dialog-content>
  <div class="c7-login-info">Enter your Commerce 7 account credentials to import all of your wine products into BottleVin</div>
  <form [formGroup]="c7LoginForm">
    <div fxLayout="row" fxLayoutAlign="center">
      <mat-form-field fxFlex="75%" appearance="outline" floatLabel="always">
        <mat-label>Email</mat-label>
        <input matInput aria-label="Commerce7 Email" placeholder="Commerce7 Email"  id="c7-user" type="text" formControlName="username">
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center">
      <mat-form-field fxFlex="75%" appearance="outline" floatLabel="always">
        <mat-label>Password</mat-label>
        <input matInput aria-label="Commerce7 Password" placeholder="Commerce7 Password" id="c7-pass" type="password" formControlName="password">
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center">
      <mat-form-field fxFlex="75%" appearance="outline" floatLabel="always">
        <mat-label>Tenant</mat-label>
        <input matInput aria-label="Commerce7 Tenant" placeholder="Commerce7 Tenant (optional)" id="c7-tenant" type="tenant" formControlName="tenant">
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [disabled]="c7LoginForm.invalid" [mat-dialog-close]="c7LoginForm.value">Submit</button>
</mat-dialog-actions>
