<div fxLayout="column">
  <div id="event-list" class="content-container">
    <app-content-card
      *ngFor="let event of events; let i = index"
      class="event-list-card"
      [title]="event.name"
      [subTitle]="subtitle(event)"
      [image]="eventImg(event)"
      [buttons]="buttons"
      (action)="eventAction(i, $event)"
    ></app-content-card>
  </div>
</div>
<span class="button-row">
  <button *ngIf="canCreate" mat-raised-button color="primary" type="button" (click)="addEvent()">Add New Event</button>
  <button *ngIf="canInsert" mat-raised-button color="primary" type="button" (click)="insertEvent()">Insert Event</button>
</span>
