import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-c7-login-dialog',
  templateUrl: './c7-login-dialog.component.html',
  styleUrls: ['./c7-login-dialog.component.scss']
})
export class C7LoginDialogComponent implements OnInit {

  constructor(
    private fb: FormBuilder
  ) { }

  c7LoginForm = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
    tenant: ['']
  });

  ngOnInit() {
  }

}
