import { Routes } from '@angular/router';
import { CallbackComponent } from './callback/callback.component';
import { Commerce7Component } from './commerce7/commerce7.component';
import { ErrorComponent } from './error/error.component';
import { ZoomAuthComponent } from './zoom-auth/zoom-auth.component';
const ɵ0 = () => import("./import/import.module.ngfactory").then(m => m.ImportModuleNgFactory), ɵ1 = () => import("./sync/sync.module.ngfactory").then(m => m.SyncModuleNgFactory), ɵ2 = () => import("./notifications/notifications.module.ngfactory").then(m => m.NotificationsModuleNgFactory), ɵ3 = () => import("./stories/stories.module.ngfactory").then(m => m.StoriesModuleNgFactory), ɵ4 = () => import("./settings/settings.module.ngfactory").then(m => m.SettingsModuleNgFactory), ɵ5 = () => import("./reports/reports.module.ngfactory").then(m => m.ReportsModuleNgFactory), ɵ6 = () => import("./cms/cms.module.ngfactory").then(m => m.CmsModuleNgFactory), ɵ7 = () => import("./users/users.module.ngfactory").then(m => m.UsersModuleNgFactory);
const routes = [
    {
        path: 'import',
        loadChildren: ɵ0,
    },
    {
        path: 'sync',
        loadChildren: ɵ1,
    },
    {
        path: 'notifications',
        loadChildren: ɵ2,
    },
    {
        path: 'stories',
        loadChildren: ɵ3,
    },
    {
        path: 'settings',
        loadChildren: ɵ4,
    },
    {
        path: 'reports',
        loadChildren: ɵ5,
    },
    {
        path: 'cms',
        loadChildren: ɵ6,
    },
    {
        path: 'users',
        loadChildren: ɵ7,
    },
    {
        path: 'callback',
        component: CallbackComponent,
    },
    {
        path: 'commerce7',
        component: Commerce7Component,
    },
    {
        path: 'error',
        component: ErrorComponent,
    },
    {
        path: 'zoom-auth',
        component: ZoomAuthComponent,
    },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
