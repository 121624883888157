import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Constants, Utils } from '../../scripts';

export interface NewMerchantDialogData {
  category: string;
}
@Component({
  selector: 'app-new-merchant-dialog',
  templateUrl: './new-merchant-dialog.component.html',
  styleUrls: ['./new-merchant-dialog.component.scss'],
})
export class NewMerchantDialogComponent implements OnInit {
  public category: string;

  constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) private data: NewMerchantDialogData) {
    this.category = data.category || Constants.MerchCategory.RESTAURANT;
  }

  newMerchantForm = this.fb.group({
    name: ['', Utils.notEmpty],
  });

  ngOnInit() {}
}
