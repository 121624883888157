export enum EventsOp {
  EditEvent,
  AddEvent,
  RemoveEvent,
}

export enum ProducerOp {
  FilterProducers,
  AddProducer,
  EditProducer,
  RemoveProducer,
}

export enum MerchantOp {
  FilterMerchants,
  AddMerchant,
  EditMerchant,
  RemoveMerchant,
}

export enum PromoOp {
  AddPromo,
  RemovePromo,
  SavePromo,
}

export enum TastingOp {
  AddTasting,
  RemoveTasting,
  SaveWinery,
  SaveMerchant,
}

export enum AppOp {
  AddApp,
  RemoveApp,
  EditApp,
}
