<div fxLayout="row">
  <label class="editor-label">{{label}}</label>
</div>
<div fxLayout="row">
  <editor
    *ngIf="active"
    apiKey="2qk7jeeptjled8zmm1jbzbg33lrpjgruhns66f8a595qwvw8"
    id="{{id}}"
    class="html-editor"
    [init]="editorInit"
    [(ngModel)]="value"
  ></editor>
</div>
