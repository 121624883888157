import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { App, AuthService, EventItem, EventsService, Merchant, MerchantStoreService, Promo, Winery, WineryStoreService } from '../core';
import { AppsService } from '../core/services/apps.service';
import { PromosService } from '../core';
import { AppOp, Constants, EventsOp, MerchantOp, ProducerOp, PromoOp } from '../scripts';

@Component({
  selector: 'app-home-page',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public auth: AuthService,
    public wineryStore: WineryStoreService,
    public merchantStore: MerchantStoreService,
    public eventService: EventsService,
    public promoService: PromosService,
    public appService: AppsService
  ) {}

  private _destroy$ = new Subject();

  tabIndex = 0;
  activeIndex = 0;
  AppTypes = Constants.AppTypes;
  wineries$ = this.wineryStore.wineries$.pipe(map(p => p.filter(w => w.category === Constants.ProdCategory.WINERY)));
  wineriesRaw$ = this.wineryStore.wineriesRaw$.pipe(map(p => p.filter(w => w.category === Constants.ProdCategory.WINERY)));
  distilleries$ = this.wineryStore.wineries$.pipe(map(p => p.filter(w => w.category === Constants.ProdCategory.DISTILLERY)));
  distilleriesRaw$ = this.wineryStore.wineriesRaw$.pipe(map(p => p.filter(w => w.category === Constants.ProdCategory.DISTILLERY)));

  shops$ = this.merchantStore.merchants$.pipe(map(p => p.filter(m => m.category === Constants.MerchCategory.SHOP)));
  shopsRaw$ = this.merchantStore.merchantsRaw$.pipe(map(p => p.filter(m => m.category === Constants.MerchCategory.SHOP)));
  restaurants$ = this.merchantStore.merchants$.pipe(map(p => p.filter(m => m.category === Constants.MerchCategory.RESTAURANT)));
  restaurantsRaw$ = this.merchantStore.merchantsRaw$.pipe(map(p => p.filter(m => m.category === Constants.MerchCategory.RESTAURANT)));
  distributors$ = this.merchantStore.merchants$.pipe(map(p => p.filter(m => m.category === Constants.MerchCategory.DISTRIBUTOR)));
  distributorsRaw$ = this.merchantStore.merchantsRaw$.pipe(map(p => p.filter(m => m.category === Constants.MerchCategory.DISTRIBUTOR)));

  ngOnInit() {
    this.wineryStore.lastError$.pipe(takeUntil(this._destroy$)).subscribe(error => {
      this.snackBar.open(error.formatted, 'dismiss', {
        duration: 8000,
        verticalPosition: 'top',
      });
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  animationDone() {
    this.activeIndex = this.tabIndex;
  }

  async eventsAction(event: [EventsOp, any]) {
    const eventObj = event[1] as EventItem;
    switch (event[0]) {
      case EventsOp.EditEvent:
        await this.router.navigateByUrl(`/cms/events/${eventObj._id}`);
        break;

      case EventsOp.AddEvent:
        this.eventService.addEvent(eventObj);
        break;

      case EventsOp.RemoveEvent:
        this.eventService.deleteEvent(eventObj);
        break;
    }
  }

  async appAction(action: [AppOp, any]) {
    const appObj = action[1] as App;
    switch (action[0]) {
      case AppOp.EditApp:
        await this.router.navigateByUrl(`/cms/apps/${appObj._id}`);
        break;

      case AppOp.AddApp:
        this.appService.addApp(appObj);
        break;

      case AppOp.RemoveApp:
        this.appService.deleteApp(appObj);
        break;
    }
  }

  async promoAction(action: [PromoOp, any]) {
    const promoObj = action[1] as Promo;
    switch (action[0]) {
      case PromoOp.AddPromo:
        this.promoService.addPromo(promoObj);
        break;

      case PromoOp.RemovePromo:
        this.promoService.deletePromo(promoObj);
        break;

      case PromoOp.SavePromo:
        this.promoService.savePromo(promoObj);
        break;
    }
  }

  async producerAction(event: [ProducerOp, any]) {
    switch (event[0]) {
      case ProducerOp.EditProducer:
        {
          const producer = event[1] as Winery;
          await this.router.navigateByUrl(`/cms/wineries/${producer._id}`);
        }
        break;

      case ProducerOp.AddProducer:
        {
          const producer = event[1] as Winery;
          this.wineryStore.addWinery(producer);
        }
        break;

      case ProducerOp.FilterProducers:
        this.wineryStore.wineryFilter = event[1];
        break;
    }
  }

  async merchantAction(event: [MerchantOp, any]) {
    switch (event[0]) {
      case MerchantOp.EditMerchant:
        {
          const merchant = event[1] as Merchant;
          await this.router.navigateByUrl(`/cms/merchants/${merchant._id}`);
        }
        break;

      case MerchantOp.AddMerchant:
        {
          const merchant = event[1] as Merchant;
          this.merchantStore.addMerchant(merchant);
        }
        break;

      case MerchantOp.FilterMerchants:
        this.merchantStore.merchantFilter = event[1];
        break;
    }
  }
}
