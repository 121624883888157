import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpHeaderInterceptor } from './interceptors';
import { CanDeactivateGuard } from './can-deactivate.guard';

import {
  ApiService,
  AuthGuard,
  AdminGuard,
  UserService,
  WineryStoreService,
} from './services';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpHeaderInterceptor, multi: true },
    ApiService,
    AuthGuard,
    AdminGuard,
    UserService,
    WineryStoreService,
    CanDeactivateGuard,
  ],
  declarations: []
})
export class CoreModule { }
