<h2 mat-dialog-title>Select {{ this.data.itemType | titlecase }}</h2>
<mat-dialog-content>
  <form [formGroup]="listSelectForm">
    <div fxLayout="row" fxLayoutAlign="center center">
      <mat-form-field fxFlex="100%">
        <mat-select [placeholder]="data.itemType | titlecase" id="select-item" formControlName="selectedItem">
          <mat-option *ngFor="let item of data.items$ | async" [value]="item[data.field]">{{ data.displayFn(item) }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="primary" [mat-dialog-close]="listSelectForm.value" [disabled]="listSelectForm.invalid">Select</button>
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
