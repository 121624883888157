<h2 mat-dialog-title>{{ action }} Promotion</h2>
<mat-dialog-content>
  <form [formGroup]="promoForm">
    <div fxLayout="row" fxLayoutGap="5px">
      <mat-form-field fxFlex="60%">
        <input matInput aria-label="Title" maxlength="28" placeholder="Title" type="text" formControlName="label" />
      </mat-form-field>
      <mat-form-field fxFlex="40%">
        <mat-select id="link-action" aria-label="Display" placeholder="Display" formControlName="action">
          <mat-option *ngFor="let type of linkActions" [value]="type.value">{{ type.label }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row">
      <mat-form-field *ngIf="promoForm.get('action').value !== 'html'" fxFlex="100%">
        <input
          matInput
          aria-label="Value"
          placeholder="{{ linkLabels[promoForm.get('action').value] }}"
          type="text"
          formControlName="value"
        />
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="5px">
      <mat-form-field fxFlex="40%">
        <input
          matInput
          aria-label="Expiration Date"
          placeholder="Expiration Date"
          [matDatepicker]="end"
          id="promo-end"
          formControlName="end"
        />
        <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
        <mat-datepicker #end></mat-datepicker>
      </mat-form-field>
      <mat-form-field *ngIf="productMenu.length > 0" fxFlex="60%">
        <mat-select placeholder="Product-specific promotion" id="product" formControlName="product">
          <mat-option *ngFor="let entry of productMenu" [value]="entry.product">{{ entry.label }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="promoForm.get('action').value === 'html'">
      <app-html-editor
        id="winery-link-html"
        [label]="linkLabels[promoForm.get('action').value]"
        [active]="promoForm.get('action').value === 'html'"
        formControlName="value"
      ></app-html-editor>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [disabled]="promoForm.invalid" [mat-dialog-close]="promoForm.value">{{ actionLabel }}</button>
</mat-dialog-actions>
