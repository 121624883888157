/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./qr-tag.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/material/tooltip";
import * as i7 from "@angular/cdk/overlay";
import * as i8 from "@angular/cdk/scrolling";
import * as i9 from "@angular/cdk/platform";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "@angular/platform-browser";
import * as i12 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i13 from "@angular/material/icon";
import * as i14 from "./qr-tag.component";
import * as i15 from "../../core/services/api.service";
import * as i16 from "@angular/material/snack-bar";
import * as i17 from "@angular/material/dialog";
var styles_QrTagComponent = [i0.styles];
var RenderType_QrTagComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QrTagComponent, data: {} });
export { RenderType_QrTagComponent as RenderType_QrTagComponent };
export function View_QrTagComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 4, "button", [["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.createQR() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(3, 212992, null, 0, i6.MatTooltip, [i7.Overlay, i1.ElementRef, i8.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i9.Platform, i4.AriaDescriber, i4.FocusMonitor, i6.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i10.Directionality], [2, i6.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i11.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "qr"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i12.View_MatIcon_0, i12.RenderType_MatIcon)), i1.ɵdid(5, 9158656, null, 0, i13.MatIcon, [i1.ElementRef, i13.MatIconRegistry, [8, null], [2, i13.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵinlineInterpolate(1, "Generate QR Code for this ", _co.itemType, ""); _ck(_v, 3, 0, currVal_2); var currVal_5 = "qr"; _ck(_v, 5, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 5).inline; var currVal_4 = (((i1.ɵnov(_v, 5).color !== "primary") && (i1.ɵnov(_v, 5).color !== "accent")) && (i1.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_3, currVal_4); }); }
export function View_QrTagComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-qr-tag", [], null, null, null, View_QrTagComponent_0, RenderType_QrTagComponent)), i1.ɵdid(1, 114688, null, 0, i14.QrTagComponent, [i15.ApiService, i16.MatSnackBar, i17.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QrTagComponentNgFactory = i1.ɵccf("app-qr-tag", i14.QrTagComponent, View_QrTagComponent_Host_0, { itemType: "itemType", itemName: "itemName", context: "context", contextId: "contextId", domain: "domain" }, {}, []);
export { QrTagComponentNgFactory as QrTagComponentNgFactory };
