<div id="app-list" class="content-container">
  <mat-card class="wine-card" *ngFor="let app of apps; let i = index">
    <mat-card-content fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
      <span fxFlex="80%">
        <mat-card-title fxLayout="row" fxLayoutGap="10px">
          <span fxFlex="30%">{{ app.settings.appName }}</span>
          <span fxFlex="70%">{{ app.settings.appType || 'System' | titlecase }} App</span>
        </mat-card-title>
        <mat-card-subtitle>{{ app._id }}</mat-card-subtitle>
      </span>
      <span fxFlex="20%">
        <span fxFlex="20%">
          <button mat-icon-button matTooltip="Edit this app" (click)="editApp(i)"><mat-icon>edit</mat-icon></button>
        </span>
        <span *ngIf="app._id !== 'CONFIG'" fxFlex="20%">
          <button mat-icon-button matTooltip="Delete this app" (click)="removeApp(i)"><mat-icon>delete</mat-icon></button>
        </span>
      </span>
    </mat-card-content>
  </mat-card>
</div>
<span class="button-row">
  <button mat-raised-button color="primary" type="button" (click)="addApp()">Add App</button>
</span>
