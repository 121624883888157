import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { List } from 'immutable';
import { AuthService, Merchant, Winery } from '../../core';
import { NewMerchantDialogComponent } from '../../home/new-merchant-dialog/new-merchant-dialog.component';
import { Constants, MerchantOp, Utils } from '../../scripts';
import { ButtonSpec } from '../content-card';

@Component({
  selector: 'app-merchant-list',
  templateUrl: './merchant-list.component.html',
  styleUrls: ['./merchant-list.component.scss'],
})
export class MerchantListComponent implements OnInit, OnChanges {
  constructor(private dialog: MatDialog, public auth: AuthService) {}

  @Input() merchants: List<Merchant>;
  @Input() rawMerchants: List<Merchant>;
  @Input() filter: string;
  @Input() category: string;
  @Input() canCreate: boolean;
  @Input() canEdit = true;
  @Input() canRemove = false;
  @Input() canFilter = true;
  @Input() canInsert = false;
  @Output() action = new EventEmitter<[MerchantOp, any]>();

  counts: {
    active: 0;
    inactive: 0;
    all: 0;
  };

  merchantFilter = Constants.WineFilters[0];
  filters = Constants.WineFilters;
  buttons: ButtonSpec[];
  filterString: string;

  ngOnInit() {
    this.buttons = [];
    if (this.canEdit) {
      this.buttons.push({ name: 'edit', icon: 'edit', tip: `Edit ${this.category} details` });
    }
    if (this.canRemove) {
      this.buttons.push({ name: 'delete', icon: 'delete', tip: `Remove ${this.category}` });
    }
  }

  ngOnChanges(): void {
    this.merchantFilter = Constants.WineFilters.find(f => f.value === this.filter);
    if (this.rawMerchants) {
      this.counts = { active: 0, inactive: 0, all: 0 };
      this.rawMerchants.forEach(p => {
        this.counts.all++;
        if (p.inactive) {
          this.counts.inactive++;
        } else {
          this.counts.active++;
        }
      });
    }
  }

  applyFilter() {
    this.action.emit([MerchantOp.FilterMerchants, this.filter]);
  }

  async merchantAction(merchant: Merchant, button) {
    switch (button) {
      case 'edit':
        await this.editMerchant(merchant);
        break;
    }
  }

  editMerchant(merchant: Merchant) {
    this.action.emit([MerchantOp.EditMerchant, merchant]);
  }

  insertMerchant() {
    const category = this.category;
    this.action.emit([MerchantOp.AddMerchant, category]);
  }

  addMerchant() {
    const dialogRef = this.dialog.open(NewMerchantDialogComponent, {
      width: '500px',
      data: {
        category: this.category,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.category = this.category;
      }
      this.action.emit([MerchantOp.AddMerchant, result]);
    });
  }

  subtitle(merchant: Merchant): string {
    if (!merchant || !merchant.contact || !merchant.contact.address) {
      return '';
    }
    const addr = merchant.contact.address;
    return addr.locality ? `${addr.locality}, ${addr.region || ''}` : `${addr.region || ''} ${addr.country || ''}`;
  }

  merchantImage(merchant: Merchant): string {
    return Utils.containerImg(merchant);
  }

  filterMerchants = (producer: Winery) => {
    if (!this.filterString || this.filterString.trim().length === 0) {
      return true;
    }
    return producer.name.toLowerCase().includes(this.filterString.toLowerCase());
  };
}
