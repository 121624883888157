import { PipeTransform, Pipe } from '@angular/core';
import { List } from 'immutable';

@Pipe({
  name: 'filterList',
  pure: false,
})
export class FilterListPipe implements PipeTransform {
  transform(items: List<any>, callback: (item: any) => boolean): any {
    if (!items || !callback) {
      return items;
    }
    return items.filter(item => callback(item));
  }
}
