import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth-guard.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermGuard extends AuthGuard implements CanActivate {

  constructor(
    protected router: Router,
    protected auth: AuthService
  ) {
    super(router, auth);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (super.canActivate(route, state)) {
      const perms = route.data.perms as Array<String>;
      return perms.every(perm => this.auth.hasPerm(perm));
    }
    return false;
  }
}

