import { Component, OnInit } from '@angular/core';
import { ApiService } from '../core';
import { Wine, WineDetail, Winery } from '../core';
import { MatDialog } from '@angular/material';
import { Utils } from '../scripts';
import { ButtonSpec } from '../shared/content-card';
import { C7LoginDialogComponent } from './c7-login-dialog/c7-login-dialog.component';

interface C7Account {
  id: string;
  avatar: string;
  firstName: string;
  lastName: string;
  email: string;
  tenants: [
    {
      id: string;
      tenantId: string;
      tenant: string;
    }
  ];
}

const BASE = '/commerce7';

@Component({
  selector: 'app-commerce7',
  templateUrl: './commerce7.component.html',
  styleUrls: ['./commerce7.component.scss'],
})
export class Commerce7Component implements OnInit {
  constructor(private api: ApiService, private dialog: MatDialog) {}

  account: C7Account;
  winery: Winery;
  wines: WineDetail[] = [];
  buttons: ButtonSpec[] = [{ name: 'edit', icon: 'refresh', tip: `Re-import Winery from Commerce7` }];

  private _username: string;
  private _password: string;
  private _tenantId: string;

  ngOnInit() {}

  showDialog() {
    const dialogRef = this.dialog.open(C7LoginDialogComponent, { width: '500px' });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._username = result.username;
        this._password = result.password;
        this._tenantId = result.tenant;
        this.fetchAccount();
      }
    });
  }

  private fetchAccount() {
    if (!this._tenantId) {
      this.api
        .post(`${BASE}/account`, {
          user: this._username,
          pass: this._password,
          tenant: this._tenantId,
        })
        .subscribe(account => {
          this.account = account;
          if (!this._tenantId) {
            this._tenantId = this.account.tenants[0].tenantId;
          }
          this.importWinery();
        });
    } else {
      this.importWinery();
    }
  }

  wineryImg(winery: Winery) {
    return Utils.containerImg(winery);
  }

  wineImg(wine: Wine) {
    return Utils.beverageImg(wine);
  }

  wineryAction(button) {
    if (button === 'edit') {
      this.importWinery();
    }
  }

  importWinery() {
    const obs = this.api.post(`${BASE}/winery`, {
      user: this._username,
      pass: this._password,
      tenant: this._tenantId,
    });
    obs.subscribe(winery => {
      this.winery = winery;
      this.importProducts();
    });
  }

  importProducts() {
    const obs = this.api.post(`${BASE}/products`, {
      user: this._username,
      pass: this._password,
      tenant: this._tenantId,
      winery: this.winery._id,
    });
    obs.subscribe(wines => {
      this.wines = wines;
    });
  }
}
