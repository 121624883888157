import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth-guard.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./auth.service";
export class AdminGuard extends AuthGuard {
    constructor(router, auth) {
        super(router, auth);
        this.router = router;
        this.auth = auth;
    }
    canActivate(route, state) {
        if (super.canActivate(route, state)) {
            return this.auth.isAdmin() || (route.data.appAdmin && this.auth.appAdmin());
        }
        return false;
    }
}
AdminGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminGuard_Factory() { return new AdminGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthService)); }, token: AdminGuard, providedIn: "root" });
