<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content>
  <div class="img-wrapper">
    <div fxLayout="column" fxLayoutAlign="center center">
      <div class="img-label">(Right-click on the below image to download)</div>
      <qrcode [qrdata]="link" [errorCorrectionLevel]="'M'" scale="5"></qrcode>
    </div>
    <div class="img-label" [innerHTML]="message"></div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true">OK</button>
</mat-dialog-actions>
