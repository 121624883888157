import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth-guard.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./auth.service";
export class PermGuard extends AuthGuard {
    constructor(router, auth) {
        super(router, auth);
        this.router = router;
        this.auth = auth;
    }
    canActivate(route, state) {
        if (super.canActivate(route, state)) {
            const perms = route.data.perms;
            return perms.every(perm => this.auth.hasPerm(perm));
        }
        return false;
    }
}
PermGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PermGuard_Factory() { return new PermGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthService)); }, token: PermGuard, providedIn: "root" });
