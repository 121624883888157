<div id="app-container">
  <app-layout-header></app-layout-header>
  <mat-sidenav-container>
    <mat-sidenav *ngIf="auth.loggedIn" mode="side" opened disableClose>
      <app-layout-sidebar></app-layout-sidebar>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
