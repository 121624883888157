import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { Constants, Utils } from '../../scripts';

@Component({
  selector: 'app-new-app-dialog',
  templateUrl: './new-app-dialog.component.html',
  styleUrls: ['./new-app-dialog.component.scss'],
})
export class NewAppDialogComponent implements OnInit {
  constructor(private fb: FormBuilder) {}

  public appTypes = Object.values(Constants.AppTypes);
  newAppForm = this.fb.group({
    id: ['', Utils.notEmpty],
    appName: ['', Utils.notEmpty],
    appType: ['', Utils.notEmpty],
  });

  ngOnInit() {}

  generateUUID() {
    this.newAppForm.controls.id.setValue(uuidv4());
    this.newAppForm.controls.id.markAsDirty();
  }
}
