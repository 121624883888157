import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { App } from '../../core';
import { AppOp } from '../../scripts';
import { ConfirmDialogComponent } from '../../shared';
import { NewAppDialogComponent } from '../new-app-dialog/new-app-dialog.component';

@Component({
  selector: 'app-app-list',
  templateUrl: './app-list.component.html',
  styleUrls: ['./app-list.component.scss'],
})
export class AppListComponent implements OnInit {
  constructor(private dialog: MatDialog) {}

  @Input() apps: App[];
  @Output() action = new EventEmitter<[AppOp, any]>();

  ngOnInit() {}

  removeApp(index) {
    const app = this.apps[index];
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {
        title: 'Delete App',
        message: `<p>This will remove the app named ${app.settings.appName} from the system. Any applications
            utilizing this app id will no longer have access to the platform.</p>
          <p>Are you sure you want to do this?</p>`,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.action.emit([AppOp.RemoveApp, app]);
      }
    });
  }

  addApp() {
    const dialogRef = this.dialog.open(NewAppDialogComponent, {
      width: '50vw',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const app: App = {
          _id: result.id,
          settings: {
            appName: result.appName,
            appType: result.appType,
          },
        };
        this.action.emit([AppOp.AddApp, app]);
      }
    });
  }

  editApp(index) {
    const app = this.apps[index];
    this.action.emit([AppOp.EditApp, app]);
  }
}
