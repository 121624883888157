import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { List } from 'immutable';
import { Observable } from 'rxjs';
import { Utils } from '../../scripts';

export interface ListSelectData {
  itemType: string;
  items$: Observable<List<any>>;
  field: string;
  displayFn: (item: any) => string;
}

@Component({
  selector: 'app-list-select-dialog',
  templateUrl: './list-select-dialog.component.html',
  styleUrls: [],
})
export class ListSelectDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ListSelectData, private fb: FormBuilder) {}

  selectMenu = [];
  listSelectForm = this.fb.group({
    selectedItem: ['', Utils.notEmpty],
  });

  ngOnInit() {}
}
