import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../core';
import { FileItem, FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { environment } from '../../../environments/environment';
import { Constants } from '../../scripts/constants';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-tag-upload',
  templateUrl: './tag-upload.component.html',
  styleUrls: ['./tag-upload.component.scss'],
})
export class TagUploadComponent implements OnInit {
  constructor(public auth: AuthService, private snackBar: MatSnackBar) {}

  uploader = new (class extends FileUploader {
    constructor(private parent: TagUploadComponent, options: FileUploaderOptions) {
      super(options);
    }

    // noinspection JSUnusedGlobalSymbols
    onCompleteAll(): any {
      return super.onCompleteAll();
    }

    // noinspection JSUnusedGlobalSymbols
    onProgressAll(progress: any): any {
      this.parent.progress.emit(progress);
      return super.onProgressAll(progress);
    }

    // noinspection JSUnusedGlobalSymbols
    onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
      this.parent.handleResult(item, response);
      return super.onSuccessItem(item, response, status, headers);
    }

    // noinspection JSUnusedGlobalSymbols
    onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
      this.parent.handleResult(item, response, true);
      return super.onErrorItem(item, response, status, headers);
    }
  })(this, {});

  public contextName = 'item';

  @Input() ident: string;
  @Input() context: string;
  @Input() contextId: string;
  @Output() progress: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit() {
    this.contextName = Constants.ContextNames[this.context];
  }

  openUpload(elemId: string) {
    // noinspection TypeScriptValidateJSTypes
    return document.getElementById(elemId).click();
  }

  uploadTags(event: any) {
    this.progress.emit(0);
    if (this.auth.accessToken) {
      this.uploader.authToken = `Bearer ${this.auth.accessToken}`;
    }
    this.uploader.options.url = `${environment.api_url}/tags/context/${this.context}/id/${this.contextId}`;
    this.uploader.addToQueue(event.target.files);
    this.uploader.uploadAll();
    event.target.value = '';
  }

  handleResult(item, result, isError = false) {
    const resp = JSON.parse(result);
    let message: string;
    if (!isError) {
      message = `Upload of '${item.file.name}' completed: ${resp.count} tags imported, ${resp.skipped} skipped`;
    } else {
      message = `Upload of '${item.file.name}' failed: ${resp.error && resp.error.message}`;
    }
    this.snackBar.open(message, 'dismiss', {
      duration: 8000,
      verticalPosition: 'top',
    });
    this.progress.emit(0);
  }
}
