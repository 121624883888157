import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule, SharedModule } from '../shared';
import { Commerce7Component } from './commerce7.component';
import { C7LoginDialogComponent } from './c7-login-dialog/c7-login-dialog.component';

@NgModule({
  declarations: [
    Commerce7Component,
    C7LoginDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
  ],
  entryComponents: [
    C7LoginDialogComponent
  ]
})
export class Commerce7Module { }
