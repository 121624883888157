<h2 mat-dialog-title>Add New {{ category | titlecase }}</h2>
<mat-dialog-content>
  <form [formGroup]="newMerchantForm">
    <div fxLayout="row" fxLayoutGap="10px">
      <mat-form-field fxFlex="100%">
        <input matInput aria-label="Name" placeholder="Name" id="winery-name" type="text" formControlName="name" />
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [disabled]="newMerchantForm.invalid" [mat-dialog-close]="newMerchantForm.value">Create</button>
</mat-dialog-actions>
