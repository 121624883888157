<h2 mat-dialog-title>Add New App</h2>
<mat-dialog-content>
  <form [formGroup]="newAppForm">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
      <mat-form-field fxFlex="75%">
        <input matInput aria-label="App ID" placeholder="ID" id="app-id" type="text" formControlName="id" />
        <mat-hint>The unique identifier for the app to be used as the key to access app content.</mat-hint>
      </mat-form-field>
      <button mat-flat-button color="accent" type="button" (click)="generateUUID()">Generate ID</button>
    </div>
    <div fxLayout="row">
      <mat-form-field fxFlex="100%">
        <input matInput aria-label="App Name" placeholder="App Name" id="app-name" type="text" formControlName="appName" />
      </mat-form-field>
    </div>
    <div fxLayout="row">
      <mat-form-field fxFlex="100%">
        <mat-select aria-label="App Type" placeholder="App Type" id="app-type" formControlName="appType">
          <mat-option *ngFor="let appType of appTypes" [value]="appType">{{ appType | titlecase }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [disabled]="newAppForm.invalid" [mat-dialog-close]="newAppForm.value">Create</button>
</mat-dialog-actions>
