import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EventItem, EventDetail, DocList } from '../models';
import { List } from 'immutable';
import { ApiService } from './api.service';
import { finalize } from 'rxjs/operators';

export interface EventError {
  context: string;
  message: string;
  event?: EventItem;
  formatted: string;
}

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private _events$: BehaviorSubject<List<EventItem>> = new BehaviorSubject(List([]));
  private _fetchingEvents = false;
  private _fetched = false;
  private _fetchingDocs = false;
  private _docs$: BehaviorSubject<List<DocList>> = new BehaviorSubject(List([]));

  private _lastError: Subject<EventError> = new Subject<EventError>();
  public lastError$: Observable<EventError> = this._lastError.asObservable();

  constructor(private apiService: ApiService) {}

  get events$() {
    if (!this._fetchingEvents && !this._fetched) {
      this._fetchingEvents = true;
      this.apiService
        .get('/events?past=true')
        .pipe(
          finalize(() => {
            this._fetchingEvents = false;
            this._fetched = true;
          })
        )
        .subscribe(
          events => {
            this._events$.next(List(events));
          },
          error => {
            this.setLastError('Retrieving events', error.message);
          }
        );
    }
    return this._events$.asObservable();
  }

  private setLastError(context: string, message: string, event?: EventItem) {
    this._lastError.next({
      context: context,
      message: message,
      event: event,
      formatted: `${context} failed because ${message}`,
    } as EventError);
  }

  private updateEventInList(event: EventItem) {
    const _events = this._events$.getValue();
    const index = _events.findIndex(w => w._id === event._id);
    this._events$.next(_events.set(index, event));
  }

  refetchEvents(): Observable<List<EventItem>> {
    this._events$.next(List([]));
    if (!this._fetchingEvents) {
      this._fetchingEvents = true;
      this.apiService
        .get('/events?past=true')
        .pipe(
          finalize(() => {
            this._fetchingEvents = false;
            this._fetched = true;
          })
        )
        .subscribe(
          events => {
            this._events$.next(List(events));
          },
          error => {
            this.setLastError('Retrieving events', error.message);
          }
        );
    }
    return this._events$.asObservable();
  }

  addEvent(event: EventItem): Observable<EventItem> {
    const returnedEvent: BehaviorSubject<EventItem> = new BehaviorSubject(event);
    this.apiService.post('/events', event).subscribe(
      newEvent => {
        returnedEvent.next(newEvent);
        this._events$.next(this._events$.getValue().insert(0, newEvent));
      },
      error => {
        this.setLastError('Adding Event', error.message, event);
      }
    );

    return returnedEvent.asObservable();
  }

  deleteEvent(event: EventItem): Observable<EventItem> {
    const returnedEvent: BehaviorSubject<EventItem> = new BehaviorSubject(event);
    const events = this._events$.getValue();
    const index = events.findIndex(s => s._id === event._id);
    if (index === -1) {
      this.setLastError('Deleting Event', 'event not in list', event);
    } else {
      this.apiService.delete(`/events/${event._id}`).subscribe(
        () => {
          returnedEvent.next({} as EventItem);
          this._events$.next(events.splice(index, 1));
        },
        error => {
          this.setLastError('Deleting event', error.message, event);
        }
      );
    }

    return returnedEvent.asObservable();
  }

  fetchEvent(eventId: string, force: boolean = false): Observable<EventDetail> {
    const fetchedEvent$: BehaviorSubject<EventDetail> = new BehaviorSubject<EventDetail>({} as EventDetail);
    const _events = this._events$.getValue();
    const _eventDetail = _events.find(w => w._id === eventId) as EventDetail;
    if (!force && _eventDetail && Array.isArray(_eventDetail.products)) {
      // Already have a full event in the list just return it
      fetchedEvent$.next(_eventDetail);
    } else {
      this.apiService.get(`/events/${eventId}`).subscribe(
        eventDetail => {
          this.updateEventInList(eventDetail);
          fetchedEvent$.next(eventDetail);
        },
        error => {
          this.setLastError('Fetching Event', error.message);
        }
      );
    }

    return fetchedEvent$.asObservable();
  }

  saveEventDetail(event: EventDetail): Observable<EventDetail> {
    const _events = this._events$.getValue();
    const index = _events.findIndex(s => s._id === event._id);
    const event$: BehaviorSubject<EventDetail> = new BehaviorSubject<EventDetail>(_events.get(index) as EventDetail);
    if (index === -1) {
      this.setLastError('Saving Event', 'event not in list', event);
    } else {
      this.apiService.put(`/events/${event._id}`, event).subscribe(
        savedEvent => {
          this.updateEventInList(savedEvent);
          event$.next(savedEvent);
        },
        error => {
          this.setLastError('Saving Event', error.message, event);
        }
      );
    }
    return event$.asObservable();
  }

  addEventProduct(event: EventDetail, product: any) {
    const event$: BehaviorSubject<EventDetail> = new BehaviorSubject<EventDetail>(event);
    this.apiService.post(`/events/${event._id}/products`, product).subscribe(savedEvent => {
      this.updateEventInList(savedEvent);
      event$.next(savedEvent);
    });
    return event$.asObservable();
  }

  removeEventProduct(event: EventDetail, productId: string) {
    const event$: BehaviorSubject<EventDetail> = new BehaviorSubject<EventDetail>(event);
    this.apiService.delete(`/events/${event._id}/products/${productId}`).subscribe(savedEvent => {
      this.updateEventInList(savedEvent);
      event$.next(savedEvent);
    });
    return event$.asObservable();
  }

  updateEventProduct(event: EventDetail, product: any) {
    const event$: BehaviorSubject<EventDetail> = new BehaviorSubject<EventDetail>(event);
    this.apiService.put(`/events/${event._id}/products/${product._id}`, product).subscribe(savedEvent => {
      this.updateEventInList(savedEvent);
      event$.next(savedEvent);
    });
    return event$.asObservable();
  }

  getDocs(event: EventDetail): Observable<List<DocList>> {
    if (event.docs.length === 0 || typeof event.docs[0] === 'object') {
      console.log(`Returning cached docs for ${event._id}`);
      const docs = event.docs as DocList[];
      this._docs$.next(List(docs));
    } else if (!this._fetchingDocs) {
      this._fetchingDocs = true;
      console.log(`Fetching docs for ${event._id}`);
      this.apiService.get(`/events/${event._id}/docs`).subscribe(
        docs => {
          event.docs = docs;
          this.updateEventInList(event);
          this._docs$.next(List(docs));
        },
        error => {
          this.setLastError('Fetching Docs', error.message, event);
        },
        () => {
          this._fetchingDocs = false;
        }
      );
    }
    return this._docs$.asObservable();
  }

  addDocList(event: EventDetail, data: DocList): Observable<List<DocList>> {
    this.apiService.post(`/events/${event._id}/docs`, data).subscribe(
      updatedEvent => {
        this.updateEventInList(updatedEvent);
        this._docs$.next(List(updatedEvent.docs));
      },
      error => {
        this.setLastError('Adding Doc List', error.message, event);
      }
    );
    return this._docs$.asObservable();
  }

  updateDocList(event: EventDetail, data: DocList): Observable<List<DocList>> {
    this.apiService.put(`/events/${event._id}/docs/${data._id}`, data).subscribe(
      updatedEvent => {
        this.updateEventInList(updatedEvent);
        this._docs$.next(List(updatedEvent.docs));
      },
      error => {
        this.setLastError('Editing Doc List', error.message, event);
      }
    );
    return this._docs$.asObservable();
  }

  deleteDocList(event: EventDetail, data: DocList): Observable<List<DocList>> {
    this.apiService.delete(`/events/${event._id}/docs/${data._id}`).subscribe(
      updatedEvent => {
        this.updateEventInList(updatedEvent);
        this._docs$.next(List(updatedEvent.docs));
      },
      error => {
        this.setLastError('Removing Doc List', error.message, event);
      }
    );
    return this._docs$.asObservable();
  }

  updateEventDocs(event: EventDetail, data: DocList[]): Observable<List<DocList>> {
    const docs$: BehaviorSubject<List<DocList>> = new BehaviorSubject<List<DocList>>(List(data));
    const docIds = data.map(d => d._id);
    this.apiService.put(`/events/${event._id}`, { ...event, docs: docIds }).subscribe(
      updatedEvent => {
        this.updateEventInList(updatedEvent);
        docs$.next(List(updatedEvent.docs));
      },
      error => {
        this.setLastError(`Saving event`, error.message, event);
      }
    );
    return docs$.asObservable();
  }
}
