/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "@angular/material/tooltip";
import * as i9 from "@angular/cdk/overlay";
import * as i10 from "@angular/cdk/scrolling";
import * as i11 from "@angular/cdk/platform";
import * as i12 from "@angular/cdk/bidi";
import * as i13 from "@angular/platform-browser";
import * as i14 from "./info-button.component";
import * as i15 from "@angular/material/snack-bar";
import * as i16 from "ngx-clipboard";
var styles_InfoButtonComponent = [i0.styles];
var RenderType_InfoButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfoButtonComponent, data: {} });
export { RenderType_InfoButtonComponent as RenderType_InfoButtonComponent };
export function View_InfoButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["mat-icon-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showInfo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 16777216, null, 0, 3, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), i1.ɵdid(4, 212992, null, 0, i8.MatTooltip, [i9.Overlay, i1.ElementRef, i10.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i11.Platform, i4.AriaDescriber, i4.FocusMonitor, i8.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i12.Directionality], [2, i8.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i13.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵted(5, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_4 = i1.ɵinlineInterpolate(1, "", _co.tooltipText, ""); _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 3).inline; var currVal_3 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_5 = _co.icon; _ck(_v, 5, 0, currVal_5); }); }
export function View_InfoButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-info-button", [], null, null, null, View_InfoButtonComponent_0, RenderType_InfoButtonComponent)), i1.ɵdid(1, 114688, null, 0, i14.InfoButtonComponent, [i15.MatSnackBar, i16.ClipboardService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InfoButtonComponentNgFactory = i1.ɵccf("app-info-button", i14.InfoButtonComponent, View_InfoButtonComponent_Host_0, { infoText: "infoText", icon: "icon", tooltipText: "tooltipText", actionText: "actionText", copyData: "copyData" }, {}, []);
export { InfoButtonComponentNgFactory as InfoButtonComponentNgFactory };
