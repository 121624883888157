<div id="promo-list" class="content-container">
  <mat-card class="wine-card" *ngFor="let promo of promos; let i = index">
    <mat-card-content fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
      <span fxFlex="80%">
        <mat-card-title fxLayout="row" fxLayoutGap="10px">
          <span fxFlex="30%">{{ promo.label }}</span>
          <span fxFlex="70%">{{ promo.action === 'html' ? 'In-App HTML' : promo.value }}</span>
        </mat-card-title>
        <mat-card-subtitle fxLayout="row" fxLayoutGap="10px">
          <span fxFlex="30%">Expiration date: {{ promo.end ? (promo.end | amDateFormat: 'll') : 'none' }}</span>
          <span *ngIf="promo.product">{{ productString(promo.product) }}</span>
        </mat-card-subtitle>
      </span>
      <span fxFlex="20%">
        <app-tag-upload
          fxFlex="20%"
          ident="promo-tag-{{ i }}"
          context="0008"
          [contextId]="promo._id"
          (progress)="uploadProgress = $event"
        ></app-tag-upload>
        <app-qr-tag
          fxFlex="20%"
          itemType="promotion"
          [itemName]="promo.label"
          context="0008"
          [contextId]="promo._id"
          [domain]="settings.qrDomain"
        ></app-qr-tag>
        <span fxFlex="20%">
          <button mat-icon-button matTooltip="Edit this promotion" (click)="editPromo(i)"><mat-icon>edit</mat-icon></button>
        </span>
        <span fxFlex="20%">
          <button mat-icon-button matTooltip="Delete this promotion" (click)="removePromo(i)"><mat-icon>delete</mat-icon></button>
        </span>
        <span fxFlex="20%">
          <app-info-button
            actionText="Copy ID"
            tooltipText="Database Info"
            [infoText]="dbInfo(promo)"
            [copyData]="promo._id"
          ></app-info-button>
        </span>
      </span>
    </mat-card-content>
  </mat-card>
</div>
<span class="button-row">
  <button mat-raised-button color="primary" type="button" (click)="addPromo()">Add Promotion</button>
</span>
<span *ngIf="uploadProgress" class="submit-progress"><mat-spinner diameter="24"></mat-spinner></span>
