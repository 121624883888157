import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ExpandedProduct } from '../../core';
import { Constants, Utils } from '../../scripts';

export interface PromoDialogData {
  products: ExpandedProduct[];
  formData: {
    label: string;
    action: string;
    value: string;
    end: string;
    product: string;
  };
}

@Component({
  selector: 'app-promo-dialog',
  templateUrl: './promo-dialog.component.html',
  styleUrls: [],
})
export class PromoDialogComponent implements OnInit {
  promoForm = this.fb.group(
    {
      label: ['', Utils.notEmpty],
      action: ['browser', Validators.required],
      value: [''],
      end: [''],
      product: [''],
    },
    { validators: Utils.linkValueValidator() }
  );
  actionLabel = 'Create';
  action = 'Add';
  linkActions = Constants.LinkActions.filter(a => a.value === 'browser' || a.value === 'embedded' || a.value === 'html');
  linkLabels = Constants.LinkLabels;
  productMenu = [];

  constructor(@Inject(MAT_DIALOG_DATA) private data: PromoDialogData, private fb: FormBuilder) {
    if (data) {
      if (data.products) {
        data.products.sort((p1, p2) => {
          const n1 = Utils.vintageName(p1.wine);
          const n2 = Utils.vintageName(p2.wine);
          if (n1 < n2) {
            return -1;
          }
          if (n1 > n2) {
            return 1;
          }
          return p1.size - p2.size;
        });
        this.productMenu = [
          {
            label: 'None',
            product: '',
          },
        ].concat(
          data.products.map(p => ({
            label: `${Utils.vintageName(p.wine)} (${Utils.volumeString(p.size)})`,
            product: p._id,
          }))
        );
      }
      if (data.formData) {
        this.actionLabel = 'Update';
        this.action = 'Edit';
        this.promoForm.patchValue(data.formData);
      }
    }
  }

  ngOnInit() {}
}
