import { Component, OnInit } from '@angular/core';
import { AuthService, WineryStoreService } from '../../core';
import { Constants } from '../../scripts';

@Component({
  selector: 'app-layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(public auth: AuthService, public wineryStore: WineryStoreService) {}

  Perms = Constants.Perms;

  public get displayName() {
    const profile = this.auth.userProfile;
    if (!this.auth.loggedIn || !profile) {
      return 'Unknown';
    }

    if (profile.name.given) {
      return `${profile.name.given} ${profile.name.family}`;
    }
    return profile.email;
  }

  ngOnInit() {}

  logout() {
    this.auth.logout();
  }
}
