import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services';
import { environment } from '../../../environments/environment';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {};

    if (req.url.startsWith(environment.api_url)) {
      headersConfig['Content-Type'] = 'application/json';
      headersConfig['Accept'] = 'application/json';

      if (this.auth.accessToken) {
        headersConfig['Authorization'] = `Bearer ${this.auth.accessToken}`;
      }
      if (this.auth.appKey) {
        headersConfig['X-API-KEY'] = this.auth.appKey;
      }
    }

    const request = req.clone({ setHeaders: headersConfig });
    return next.handle(request);
  }
}
