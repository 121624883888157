import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExpandedProduct, Promo, Settings } from '../../core';
import { ConfirmDialogComponent } from '../dialogs';
import { MatDialog } from '@angular/material';
import { PromoDialogComponent } from '../dialogs';
import { PromoOp, Utils } from '../../scripts';

@Component({
  selector: 'app-promo-list',
  templateUrl: './promo-list.component.html',
  styleUrls: ['./promo-list.component.scss'],
})
export class PromoListComponent implements OnInit {
  constructor(private dialog: MatDialog) {}

  uploadProgress: number;

  @Input() promos: Promo[];
  @Input() products: ExpandedProduct[];
  @Input() settings: Settings;
  @Output() action = new EventEmitter<[PromoOp, any]>();

  ngOnInit() {}

  productString(productId) {
    const product = this.products.find(p => p._id === productId);
    return product && product.wine && `${Utils.vintageName(product.wine)} (${Utils.volumeString(product.size)})`;
  }

  removePromo(index) {
    const promo = this.promos[index];
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {
        title: 'Delete Promotion',
        message:
          `<p>This will remove the promotion titled ${promo.label} ` +
          `and will also invalidate any tags associated with this promotion.</p>` +
          `<p>Are you sure you want to do this?</p>`,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.action.emit([PromoOp.RemovePromo, promo]);
      }
    });
  }

  dbInfo(promo: Promo) {
    return `Database Info\nId: ${promo._id}\nCreated: ${Utils.dateStrFromObjectid(promo._id)}`;
  }

  addPromo() {
    const dialogRef = this.dialog.open(PromoDialogComponent, {
      width: '50vw',
      data: {
        products: this.products,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.action.emit([PromoOp.AddPromo, result as Promo]);
      }
    });
  }

  editPromo(index) {
    const promo = this.promos[index];
    const dialogRef = this.dialog.open(PromoDialogComponent, {
      width: '50vw',
      data: {
        products: this.products,
        formData: promo,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result._id = promo._id;
        this.action.emit([PromoOp.SavePromo, result as Promo]);
      }
    });
  }
}
