import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MomentModule } from 'ngx-moment';

import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';

import { HeaderComponent, SidebarComponent, SharedModule } from './shared';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core';
import { AuthService } from './core';
import { CallbackComponent } from './callback/callback.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared';
import { Commerce7Module } from './commerce7/commerce7.module';
import { ErrorModule } from './error/error.module';
import { ZoomAuthComponent } from './zoom-auth/zoom-auth.component';

@NgModule({
  declarations: [AppComponent, HeaderComponent, SidebarComponent, CallbackComponent, ZoomAuthComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MomentModule,
    CoreModule,
    SharedModule,
    MaterialModule,
    HomeModule,
    AppRoutingModule,
    Commerce7Module,
    ErrorModule,
  ],
  providers: [AuthService],
  bootstrap: [AppComponent],
})
export class AppModule {}
