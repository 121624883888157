import { Component, Inject, OnInit, SecurityContext } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

export interface ConfirmDialogData {
  title: string;
  message: string;
  yesBtn: string;
  noBtn: string;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
})

export class ConfirmDialogComponent implements OnInit {

  public title: string;
  public message: string;
  public yesBtn: string;
  public noBtn: string;

  constructor(
    private dom: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) private data: ConfirmDialogData
  ) {
    this.title = data.title || 'Confirmation';
    this.message = data.message ? this.dom.sanitize(SecurityContext.HTML, data.message) : 'Are you sure?';
    this.yesBtn = data.yesBtn || 'Yes';
    this.noBtn = data.noBtn || 'No';
  }

  ngOnInit() {
  }

}
