<div class="page">
  <div fxLayout="row" fxLayoutGap="20px">
    <div fxFlex="40%">
      <h3 class="header-font">{{ !winery ? 'Commerce7 Integration' : 'Imported Winery' }}</h3>
      <div *ngIf="!winery">
        <p class="c7-description">
          This is a placeholder description explaining about importing wine products from a Commerce7 account into the BottleVin system. The
          button below will launch a dialog into which the user will enter their Commerce7 credentials. This will then import the winery
          info and all of the product data into the BottleVin system and make it available in the mobile application.
        </p>
        <button mat-raised-button color="primary" (click)="showDialog()">Import</button>
      </div>
      <div *ngIf="winery">
        <app-content-card
          [title]="winery.name"
          [image]="wineryImg(winery)"
          [buttons]="buttons"
          (action)="wineryAction($event)"
        ></app-content-card>
      </div>
    </div>
    <div fxFlex="40%" *ngIf="winery">
      <h3 class="header-font">Wines</h3>
      <div fxLayout="column" fxLayoutGap="10px" class="content-container" id="c7-products">
        <app-content-card *ngFor="let wine of wines" [title]="wine.name" [image]="wineImg(wine)"></app-content-card>
      </div>
      <button mat-raised-button color="primary" type="button" (click)="importProducts()">Refresh Products</button>
    </div>
  </div>
</div>
