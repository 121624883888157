import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor() { }

  errorMap = {
    400: 'Invalid Request',
    401: 'Un-authorized',
    403: 'Access Denied',
    404: 'Not Found',
    500: 'Server Error',
  };

  error = history.state.error;

  ngOnInit() {
  }

}
