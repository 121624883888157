import { BehaviorSubject, Subject } from 'rxjs';
import { List } from 'immutable';
import { Constants } from '../../scripts';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { HttpParams } from '@angular/common/http';
import { finalize, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./auth.service";
export class WineryStoreService {
    constructor(apiService, auth) {
        this.apiService = apiService;
        this.auth = auth;
        this._wineryFilter = JSON.parse(localStorage.getItem('wineryFilter')) || 'all';
        this._wineFilter$ = new BehaviorSubject('active');
        this._fetchingWineries = false;
        this._wineriesFetched = false;
        this._fetchingAll = false;
        this._allFetched = false;
        this._wineries$ = new BehaviorSubject(List([]));
        // This list may be filtered
        this.wineries$ = this.wineriesRaw$.pipe(map(w => this.filterWineries(w)));
        this._fetchingSettings = false;
        this._settingsFetched = false;
        this._fetchingDocs = false;
        this._docs$ = new BehaviorSubject(List([]));
        this._settings$ = new BehaviorSubject({});
        this._fetchingStyles = false;
        this._styles = new BehaviorSubject([]);
        this._styles$ = this._styles.asObservable();
        this._fetchingVarietals = false;
        this._varietals = new BehaviorSubject({ red: [], white: [] });
        this._varietals$ = this._varietals.asObservable();
        this._lastError = new Subject();
        this.lastError$ = this._lastError.asObservable();
        this._AllWineryID = '__ALL__';
        // This is placeholder entry for selected all wineries within the list (only available to Admin)
        this._allWinery = {
            _id: this._AllWineryID,
            name: 'All Producers',
            contact: {
                address: {
                    street_address: '2905 Stender Way',
                    locality: 'Santa Clara',
                    region: 'CA',
                    postal_code: '95054',
                    country: 'USA',
                },
                email: 'admin@bottlevin.com',
                phone: '',
                website: 'https://www.bottlevin.com',
            },
        };
    }
    get settings$() {
        if (this.auth.loggedIn && !this._fetchingSettings && !this._settingsFetched) {
            this._fetchingSettings = true;
            this.apiService
                .get('/settings')
                .pipe(finalize(() => {
                this._fetchingSettings = false;
            }))
                .subscribe(settings => {
                this._settingsFetched = true;
                this._settings$.next(settings);
            }, error => {
                this.setLastError('Fetching settings', error.message);
                this._settingsFetched = true;
                this._settings$.next({});
            });
        }
        return this._settings$.asObservable();
    }
    get styles$() {
        if (!this._fetchingStyles && this._styles.getValue().length === 0) {
            this._fetchingStyles = true;
            this.apiService
                .get('/styles')
                .pipe(finalize(() => {
                this._fetchingStyles = false;
            }))
                .subscribe(styles => {
                this._styles.next(styles);
            }, error => {
                this.setLastError('Fetching styles', error.message);
                this._styles.next([]);
            });
        }
        return this._styles$;
    }
    get varietals$() {
        const curVal = this._varietals.getValue();
        if (!this._fetchingVarietals && curVal.red.length === 0 && curVal.white.length === 0) {
            this._fetchingVarietals = true;
            this.apiService
                .get('/varietals')
                .pipe(finalize(() => {
                this._fetchingVarietals = false;
            }))
                .subscribe(varietals => {
                this._varietals.next(varietals);
            }, error => {
                this.setLastError('Fetching varietals', error.message);
                this._varietals.next({ red: [], white: [] });
            });
        }
        return this._varietals$;
    }
    get wineryFilter() {
        return this._wineryFilter;
    }
    set wineryFilter(filterValue) {
        this._wineryFilter = filterValue;
        this._wineries$.next(this._wineries$.value);
        localStorage.setItem('wineryFilter', JSON.stringify(filterValue));
    }
    get wineFilter$() {
        return this._wineFilter$.asObservable();
    }
    set wineFilter(filterValue) {
        this._wineFilter$.next(filterValue);
    }
    get wineriesRaw$() {
        this.auth.loggedIn$.subscribe(loggedIn => {
            if (!this._fetchingWineries && !this._wineriesFetched) {
                if (loggedIn) {
                    this._fetchingWineries = true;
                    this.apiService
                        .get('/wineries')
                        .pipe(finalize(() => {
                        this._fetchingWineries = false;
                        this._wineriesFetched = true;
                    }))
                        .subscribe(wineries => {
                        this._wineries$.next(List(wineries));
                    });
                }
            }
        });
        return this._wineries$.asObservable();
    }
    get wineriesAll$() {
        if (!this._fetchingAll && !this._allFetched) {
            let params;
            if (this.auth.isAdmin()) {
                params = new HttpParams().set('details', 'true');
            }
            this._fetchingAll = true;
            this.apiService
                .get('/wineries', params)
                .pipe(finalize(() => {
                this._fetchingAll = false;
                this._allFetched = true;
            }))
                .subscribe(wineries => {
                if (this.auth.isAdmin()) {
                    wineries = [this._allWinery, ...wineries];
                }
                this._wineries$.next(List(wineries));
            });
        }
        return this._wineries$.asObservable();
    }
    static applyFilter(w, filter) {
        switch (filter) {
            case 'active':
                return !w.inactive;
            case 'inactive':
                return w.inactive;
            default:
                return true;
        }
    }
    filterWineries(wineries) {
        return wineries.filter(w => {
            if (this.isAll(w)) {
                return true;
            }
            return WineryStoreService.applyFilter(w, this._wineryFilter);
        });
    }
    isAll(winery) {
        return winery._id === this._AllWineryID;
    }
    setLastError(context, message, winery, detail) {
        const lastError = {
            context: context,
            message: message,
            winery: winery,
            detail: detail,
            formatted: `${context} failed because ${message}`,
        };
        this._lastError.next(lastError);
    }
    updateWineryInList(winery) {
        const _wineries = this._wineries$.getValue();
        const index = _wineries.findIndex(w => w._id === winery._id);
        this._wineries$.next(_wineries.set(index, winery));
    }
    getCountries() {
        const regionList = new BehaviorSubject([]);
        this.apiService.get('/countries').subscribe(countries => {
            regionList.next(countries);
        });
        return regionList.asObservable();
    }
    getRegions(country) {
        const regionList = new BehaviorSubject([]);
        this.apiService.get(`/regions/${country}`).subscribe(regions => {
            regionList.next(regions);
        });
        return regionList.asObservable();
    }
    getAppellations(country, region) {
        const avaList = new BehaviorSubject([]);
        this.apiService.get(`/regions/${country}/${region}`).subscribe(appellations => {
            avaList.next(appellations);
        });
        return avaList.asObservable();
    }
    refetchWineries() {
        this._wineries$.next(List([]));
        if (!this._fetchingWineries) {
            this._fetchingWineries = true;
            this.apiService
                .get('/wineries')
                .pipe(finalize(() => {
                this._fetchingWineries = false;
                this._wineriesFetched = true;
            }))
                .subscribe(wineries => {
                this._wineries$.next(List(wineries));
            });
        }
        return this._wineries$;
    }
    /**
     * Retrieve a winery by ID without making it the selected winery
     *
     * @param wineryId - the winery to retreive
     * @param force - retrieve a new copy even if we have a full winery detail in the list
     */
    fetchWinery(wineryId, force = false) {
        const _wineries = this._wineries$.getValue();
        const _winery = _wineries.find(w => w._id === wineryId);
        const winery$ = new BehaviorSubject({});
        if (_winery) {
            const wineryDetail = _winery;
            if (!force && Array.isArray(wineryDetail.admins)) {
                // If we already have a full winery detail just use the current one in the list
                console.log(`Returning cached winery ${wineryId}`);
                winery$.next(wineryDetail);
            }
            else {
                console.log(`Fetching winery ${wineryId}`);
                this.apiService.get(`/wineries/${wineryId}`).subscribe(winery => {
                    this.updateWineryInList(winery);
                    winery$.next(winery);
                }, error => {
                    this.setLastError('Fetching producer', error.message);
                });
            }
        }
        else {
            this.setLastError(`Fetching producer`, `producer with id ${wineryId} not in list`);
        }
        return winery$.asObservable();
    }
    filteredWinery(winery, wineFilter) {
        const collection = Constants.Prod2Collection[winery.category];
        const filtered = winery[collection] && winery[collection].filter(w => WineryStoreService.applyFilter(w, wineFilter));
        return Object.assign({}, winery, { [collection]: filtered });
    }
    filteredWineryDetail(winery, wineFilter) {
        const collection = Constants.Prod2Collection[winery.category];
        const filtered = winery[collection] && winery[collection].filter(w => WineryStoreService.applyFilter(w, wineFilter));
        return Object.assign({}, winery, { [collection]: filtered });
    }
    addWinery(winery) {
        const winery$ = new BehaviorSubject({});
        this.apiService.post('/wineries', winery).subscribe(newWinery => {
            this._wineries$.next(this._wineries$.getValue().push(newWinery));
            winery$.next(newWinery);
        }, error => {
            this.setLastError(`Adding ${winery.category}`, error.message, winery);
        });
        return winery$.asObservable();
    }
    saveWinery(winery) {
        const _wineries = this._wineries$.getValue();
        const index = _wineries.findIndex(w => w._id === winery._id);
        const winery$ = new BehaviorSubject(_wineries.get(index));
        if (!this.isAll(winery)) {
            this.apiService.put(`/wineries/${winery._id}`, winery).subscribe(savedWinery => {
                this.updateWineryInList(savedWinery);
                winery$.next(savedWinery);
            }, error => {
                this.setLastError(`Saving ${winery.category}`, error.message, winery);
            });
        }
        return winery$.asObservable();
    }
    newEvent(winery, event) {
        const winery$ = new BehaviorSubject(winery);
        this.apiService.post(`/wineries/${winery._id}/events`, event).subscribe(updatedWinery => {
            this.updateWineryInList(updatedWinery);
            winery$.next(updatedWinery);
        }, error => {
            this.setLastError(`Adding new event to ${winery.category}`, error.message, winery);
        });
        return winery$.asObservable();
    }
    addEvent(winery, eventId) {
        const winery$ = new BehaviorSubject(winery);
        this.apiService.put(`/wineries/${winery._id}/events/${eventId}`).subscribe(updatedWinery => {
            this.updateWineryInList(updatedWinery);
            winery$.next(updatedWinery);
        }, error => {
            this.setLastError(`Adding event to ${winery.category}`, error.message, winery);
        });
        return winery$.asObservable();
    }
    removeEvent(winery, event) {
        const winery$ = new BehaviorSubject(winery);
        this.apiService.delete(`/wineries/${winery._id}/events/${event._id}`).subscribe(updatedWinery => {
            this.updateWineryInList(updatedWinery);
            winery$.next(updatedWinery);
        }, error => {
            this.setLastError(`Removing event from ${winery.category}`, error.message, winery);
        });
        return winery$.asObservable();
    }
    addPromo(winery, promo) {
        const winery$ = new BehaviorSubject(winery);
        this.apiService.post(`/wineries/${winery._id}/promos`, promo).subscribe(updatedWinery => {
            this.updateWineryInList(updatedWinery);
            winery$.next(updatedWinery);
        }, error => {
            this.setLastError(`Adding promo to ${winery.category}`, error.message, winery);
        });
        return winery$.asObservable();
    }
    updatePromo(winery, promo) {
        const winery$ = new BehaviorSubject(winery);
        this.apiService.put(`/wineries/${winery._id}/promos/${promo._id}`, promo).subscribe(updatedWinery => {
            this.updateWineryInList(updatedWinery);
            winery$.next(updatedWinery);
        }, error => {
            this.setLastError(`Updating promo in ${winery.category}`, error.message, winery);
        });
        return winery$.asObservable();
    }
    removePromo(winery, promo) {
        const winery$ = new BehaviorSubject(winery);
        this.apiService.delete(`/wineries/${winery._id}/promos/${promo._id}`).subscribe(updatedWinery => {
            this.updateWineryInList(updatedWinery);
            winery$.next(updatedWinery);
        }, error => {
            this.setLastError(`Removing promo from ${winery.category}`, error.message, winery);
        });
        return winery$.asObservable();
    }
    fetchProducts(winery) {
        const wineProducts = new BehaviorSubject([]);
        this.apiService.get(`/wineries/${winery._id}/products`).subscribe(products => {
            wineProducts.next(products);
        }, error => {
            this.setLastError('Fetching products', error.message, winery);
        });
        return wineProducts.asObservable();
    }
    addWine(winery, wine) {
        const collection = Constants.Bev2Collection[wine.category];
        const winery$ = new BehaviorSubject(winery);
        this.apiService.post(`/wineries/${winery._id}/${collection}`, wine).subscribe(updatedWinery => {
            this.updateWineryInList(updatedWinery);
            winery$.next(updatedWinery);
        }, error => {
            this.setLastError('Adding wine', error.message, winery, wine);
        });
        return winery$.asObservable();
    }
    copyWine(winery, wineId, newWine) {
        const collection = Constants.Bev2Collection[newWine.category];
        const winery$ = new BehaviorSubject(winery);
        // Make sure there is no ID on the new wine data to copy
        delete newWine._id;
        const items = collection === 'oils' ? winery.sundries[collection] : winery[collection];
        if (items.find(w => w._id === wineId)) {
            this.apiService.post(`/wineries/${winery._id}/${collection}/${wineId}`, newWine).subscribe(updatedWinery => {
                this.updateWineryInList(updatedWinery);
                winery$.next(updatedWinery);
            }, error => {
                this.setLastError('Copying wine', error.message, winery, newWine);
            });
        }
        else {
            this.setLastError('Copying wine', `invalid ${collection} id ${wineId} supplied`, winery, newWine);
        }
        return winery$.asObservable();
    }
    removeWine(winery, wine) {
        const collection = Constants.Bev2Collection[wine.category];
        const wineId = wine._id;
        const winery$ = new BehaviorSubject(winery);
        const items = collection === 'oils' ? winery.sundries[collection] : winery[collection];
        if (items.find(w => w._id === wineId)) {
            this.apiService.delete(`/wineries/${winery._id}/${collection}/${wineId}`).subscribe(updatedWinery => {
                this.updateWineryInList(updatedWinery);
                winery$.next(updatedWinery);
            });
        }
        else {
            this.setLastError('Removing wine', `invalid ${collection} id ${wineId} supplied`, winery);
        }
        return winery$.asObservable();
    }
    fetchWine(winery, wineId, collection = '') {
        collection = collection || Constants.Prod2Collection[winery.category];
        const items = collection === 'oils' ? winery.sundries[collection] : winery[collection];
        const windex = items.findIndex(w => w._id === wineId);
        const wine$ = new BehaviorSubject({});
        if (windex !== -1) {
            const wineDetail = items[windex];
            if (Array.isArray(wineDetail.images)) {
                // If this already looks like a fully fetched wine, don't retrieve it again
                console.log(`Returning cached wine ${wineId}`);
                wine$.next(wineDetail);
            }
            else {
                console.log(`Fetching wine ${wineId}`);
                this.apiService.get(`/wineries/${winery._id}/${collection}/${wineId}`).subscribe(fetchedWine => {
                    items[windex] = fetchedWine;
                    this.updateWineryInList(winery);
                    wine$.next(fetchedWine);
                }, error => {
                    this.setLastError(`Fetching beverage`, error.message, winery);
                });
            }
        }
        else {
            this.setLastError('Fetching beverage', `invalid ${collection} id ${wineId} supplied`, winery);
        }
        return wine$.asObservable();
    }
    saveWine(winery, wine) {
        const collection = Constants.Bev2Collection[wine.category];
        const items = collection === 'oils' ? winery.sundries[collection] : winery[collection];
        const windex = items.findIndex(w => w._id === wine._id);
        const wine$ = new BehaviorSubject(wine);
        if (windex !== -1) {
            this.apiService.put(`/wineries/${winery._id}/wines/${wine._id}`, wine).subscribe(savedWine => {
                items[windex] = savedWine;
                this.updateWineryInList(winery);
                wine$.next(savedWine);
            }, error => {
                this.setLastError('Saving wine', error.message, winery, wine);
            });
        }
        else {
            this.setLastError('Saving wine', `${wine.category} ${wine.name} not found in ${winery.category}`, winery);
        }
        return wine$.asObservable();
    }
    fetchProductsForWine(winery, wineId) {
        const wineProducts = new BehaviorSubject(List([]));
        this.apiService.get(`/wineries/${winery._id}/wines/${wineId}/products`).subscribe(products => {
            wineProducts.next(products);
        }, error => {
            this.setLastError('Fetching products', error.message, winery);
        });
        return wineProducts.asObservable();
    }
    addProductForWine(winery, wineId, product, products) {
        const wineProducts = new BehaviorSubject(products);
        this.apiService.post(`/wineries/${winery._id}/wines/${wineId}/products`, product).subscribe(newProducts => {
            wineProducts.next(newProducts);
        }, error => {
            this.setLastError('Adding product', error.message, winery);
        });
        return wineProducts.asObservable();
    }
    updateProductForWine(winery, wineId, product, products) {
        const wineProducts = new BehaviorSubject(products);
        this.apiService.put(`/wineries/${winery._id}/wines/${wineId}/products/${product._id}`, product).subscribe(newProducts => {
            wineProducts.next(newProducts);
        }, error => {
            this.setLastError('Saving product', error.message, winery);
        });
        return wineProducts.asObservable();
    }
    removeProductForWine(winery, wineId, product, products) {
        const wineProducts = new BehaviorSubject(products);
        this.apiService.delete(`/wineries/${winery._id}/wines/${wineId}/products/${product._id}`).subscribe(newProducts => {
            wineProducts.next(newProducts);
        }, error => {
            this.setLastError('Removing product', error.message, winery);
        });
        return wineProducts.asObservable();
    }
    fetchTastingByIndex(winery, index, force = false) {
        const tasting$ = new BehaviorSubject({});
        if (winery.menu.length > index) {
            const tasting = winery.menu[index];
            if (!force && Array.isArray(tasting.products)) {
                tasting$.next(tasting);
            }
            else {
                this.apiService.get(`/wineries/${winery._id}/tastings/${tasting._id}`).subscribe(wineTasting => {
                    winery.menu[index] = wineTasting;
                    this.updateWineryInList(winery);
                    tasting$.next(wineTasting);
                }, error => {
                    this.setLastError('Fetching tasting', error.message, winery, tasting);
                });
            }
        }
        else {
            this.setLastError('Fetching tasting', `invalid index ${index} supplied`, winery);
        }
        return tasting$.asObservable();
    }
    saveTasting(winery, tasting) {
        const tasting$ = new BehaviorSubject({});
        this.apiService.put(`/wineries/${winery._id}/tastings/${tasting._id}`, tasting).subscribe(tastingDetail => {
            const tindex = winery.menu.findIndex(t => t._id === tasting._id);
            winery.menu[tindex] = tastingDetail;
            this.updateWineryInList(winery);
            tasting$.next(tastingDetail);
        }, error => {
            this.setLastError('Saving wine', error.message, winery, tasting);
        });
        return tasting$.asObservable();
    }
    addTasting(winery, tasting) {
        const winery$ = new BehaviorSubject(winery);
        this.apiService.post(`/wineries/${winery._id}/tastings`, tasting).subscribe(updatedWinery => {
            this.updateWineryInList(updatedWinery);
            winery$.next(updatedWinery);
        }, error => {
            this.setLastError('Adding tasting', error.message, winery, tasting);
        });
        return winery$.asObservable();
    }
    removeTastingByIndex(winery, index) {
        const tasting = winery.menu[index];
        const winery$ = new BehaviorSubject(winery);
        this.apiService.delete(`/wineries/${winery._id}/tastings/${tasting._id}`).subscribe(updatedWinery => {
            this.updateWineryInList(updatedWinery);
            winery$.next(updatedWinery);
        }, error => {
            this.setLastError('Removing tasting', error.message, winery, tasting);
        });
        return winery$.asObservable();
    }
    getUpcs(winery) {
        const upcs$ = new BehaviorSubject(List([]));
        if (winery.upcs.length === 0 || typeof winery.upcs[0] === 'object') {
            console.log(`Returning cached upcs for ${winery._id}`);
            const tags = winery.upcs;
            upcs$.next(List(tags));
        }
        else {
            console.log(`Fetching upcs for ${winery._id}`);
            this.apiService.get(`/wineries/${winery._id}/upcs`).subscribe(tags => {
                winery.upcs = tags;
                this.updateWineryInList(winery);
                upcs$.next(tags);
            }, error => {
                this.setLastError('Fetching UPCs', error.message, winery);
            });
        }
        return upcs$.asObservable();
    }
    addUpc(winery, data) {
        const winery$ = new BehaviorSubject(winery);
        this.apiService.post(`/wineries/${winery._id}/upcs`, data).subscribe(updatedWinery => {
            this.updateWineryInList(updatedWinery);
            winery$.next(updatedWinery);
        }, error => {
            this.setLastError('Adding UPC', error.message, winery);
        });
        return winery$.asObservable();
    }
    editUpcs(winery, data) {
        const winery$ = new BehaviorSubject(winery);
        this.apiService.put(`/wineries/${winery._id}/upcs/${data.tagId}`, data).subscribe(updatedWinery => {
            this.updateWineryInList(updatedWinery);
            winery$.next(updatedWinery);
        }, error => {
            this.setLastError('Editing UPC', error.message, winery);
        });
        return winery$.asObservable();
    }
    deleteUpc(winery, data) {
        const winery$ = new BehaviorSubject(winery);
        this.apiService.delete(`/wineries/${winery._id}/upcs/${data.tagId}`).subscribe(updatedWinery => {
            this.updateWineryInList(updatedWinery);
            winery$.next(updatedWinery);
        }, error => {
            this.setLastError('Removing UPC', error.message, winery);
        });
        return winery$.asObservable();
    }
    getDocs(winery) {
        if (winery.docs.length === 0 || typeof winery.docs[0] === 'object') {
            console.log(`Returning cached docs for ${winery._id}`);
            const docs = winery.docs;
            this._docs$.next(List(docs));
        }
        else if (!this._fetchingDocs) {
            console.log(`Fetching docs for ${winery._id}`);
            this.apiService.get(`/wineries/${winery._id}/docs`).subscribe(docs => {
                winery.docs = docs;
                this.updateWineryInList(winery);
                this._docs$.next(List(docs));
            }, error => {
                this.setLastError('Fetching Docs', error.message, winery);
            }, () => {
                this._fetchingDocs = true;
            });
        }
        return this._docs$.asObservable();
    }
    addDocList(winery, data) {
        this.apiService.post(`/wineries/${winery._id}/docs`, data).subscribe(updatedWinery => {
            this.updateWineryInList(updatedWinery);
            this._docs$.next(List(updatedWinery.docs));
        }, error => {
            this.setLastError('Adding Doc List', error.message, winery);
        });
        return this._docs$.asObservable();
    }
    updateDocList(winery, data) {
        this.apiService.put(`/wineries/${winery._id}/docs/${data._id}`, data).subscribe(updatedWinery => {
            this.updateWineryInList(updatedWinery);
            this._docs$.next(List(updatedWinery.docs));
        }, error => {
            this.setLastError('Editing Doc List', error.message, winery);
        });
        return this._docs$.asObservable();
    }
    deleteDocList(winery, data) {
        this.apiService.delete(`/wineries/${winery._id}/docs/${data._id}`).subscribe(updatedWinery => {
            this.updateWineryInList(updatedWinery);
            this._docs$.next(List(updatedWinery.docs));
        }, error => {
            this.setLastError('Removing Doc List', error.message, winery);
        });
        return this._docs$.asObservable();
    }
    updateWineryDocs(winery, data) {
        const docIds = data.map(d => d._id);
        this.apiService.put(`/wineries/${winery._id}`, Object.assign({}, winery, { docs: docIds })).subscribe(updatedWinery => {
            this.updateWineryInList(updatedWinery);
            this._docs$.next(List(updatedWinery.docs));
        }, error => {
            this.setLastError(`Saving ${winery.category}`, error.message, winery);
        });
        return this._docs$.asObservable();
    }
}
WineryStoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WineryStoreService_Factory() { return new WineryStoreService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.AuthService)); }, token: WineryStoreService, providedIn: "root" });
