import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-info-button',
  templateUrl: './info-button.component.html',
  styleUrls: ['./info-button.component.scss']
})
export class InfoButtonComponent implements OnInit {

  constructor(
    private snackBar: MatSnackBar,
    private clipboard: ClipboardService,
  ) { }

  @Input() infoText: string;
  @Input() icon?: string;
  @Input() tooltipText?: string;
  @Input() actionText?: string;
  @Input() copyData?: string;

  ngOnInit() {
    if (!this.icon) {
      this.icon = 'info';
    }
    if (!this.tooltipText) {
      this.tooltipText = 'Show Info';
    }
    if (!this.actionText) {
      this.actionText = 'Dismiss';
    }
  }

  showInfo() {
    const sbRef = this.snackBar.open(this.infoText, 'Copy ID', {
      duration: 8000,
      verticalPosition: 'top',
      panelClass: 'dbinfo-text'
    });
    if (this.copyData) {
      sbRef.onAction().subscribe(
        () => {
          this.clipboard.copyFromContent(this.copyData);
        }
      );
    }
  }
}
