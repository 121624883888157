import { Component, Inject, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MAT_DIALOG_DATA } from '@angular/material';

export interface ImageDialogData {
  name: string;
  link: string;
}

@Component({
  selector: 'app-image-dialog',
  templateUrl: './qr-dialog.component.html',
  styleUrls: ['./qr-dialog.component.scss']
})
export class QrDialogComponent implements OnInit {

  title: string;
  link: string;
  message: string;

  constructor(
    private dom: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) private data: ImageDialogData
  ) {
    this.title = `QR Code for ${data.name}`;
    this.link = data.link || '#';
    this.message = this.dom.sanitize(SecurityContext.HTML, `QR encoded for <a target="_blank" href="${this.link}">${this.link}</a>`);
  }

  ngOnInit() {
  }

}
