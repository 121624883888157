import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private router: Router,

  ) {}

  private _handleError(response: any): Observable<any> {
    if (response.status === 401) {
      this.auth.login();
    }
    else if (response.status === 403) {
      this.router.navigate(['error'], {state: response.error});
    }
    console.warn(response);
    return throwError(response.error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.api_url}${path}`, { params }
    ).pipe(catchError(error => this._handleError(error)));
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${environment.api_url}${path}`,
      JSON.stringify(body)
    ).pipe(catchError(error => this._handleError(error)));
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${environment.api_url}${path}`,
      JSON.stringify(body)
    ).pipe(catchError(error => this._handleError(error)));
  }

  delete(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.delete(`${environment.api_url}${path}`, { params }
    ).pipe(catchError(error => this._handleError(error)));
  }
}
